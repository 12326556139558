// Merge the two arrays: left and right
function merge(left, right, elementType = "default") {
    let resultArray = [],
      leftIndex = 0,
      rightIndex = 0;
  
    // We will concatenate values into the resultArray in order
    while (leftIndex < left.length && rightIndex < right.length) {
        let compareCond;
        let compareData = {};
        if (elementType === "location") {
        // reversing > instead of <
        compareCond = left[leftIndex]["count"] > right[rightIndex]["count"];
        if (compareCond) {
          resultArray.push(left[leftIndex]);
          leftIndex++; // move left array cursor
        } else {
          resultArray.push(right[rightIndex]);
          rightIndex++; // move right array cursor
        }
      } else if (elementType === "ageSplit") {
        // [{"range": count}...]
        const leftKey = Object.keys(left[leftIndex])[0];
        const rightKey = Object.keys(right[rightIndex])[0];
        compareData.l = left[leftIndex][leftKey];
        compareData.r = right[rightIndex][rightKey];
        
        // reversing > instead of <
        compareCond = compareData.l > compareData.r;
        if (compareCond) {
          resultArray.push(left[leftIndex]);
          leftIndex++; // move left array cursor
        } else {
          resultArray.push(right[rightIndex]);
          rightIndex++; // move right array cursor
        }
      } else if (elementType = 'top') {
        // reversing, > instead of <
        compareCond = left[leftIndex][1] > right[rightIndex][1];
      } else {
        compareCond = left[leftIndex] < right[rightIndex];
      }
      
      if (compareCond) {
        resultArray.push(left[leftIndex]);
        leftIndex++; // move left array cursor
      } else {
        resultArray.push(right[rightIndex]);
        rightIndex++; // move right array cursor
      }
    }
  
    // We need to concat here because there will be one element remaining
    // from either left OR the right
    return resultArray
      .concat(left.slice(leftIndex))
      .concat(right.slice(rightIndex));
  }
  
  // Merge Sort Implentation (Recursion)
  function mergeSort(unsortedArray, elementType) {
    // No need to sort the array if the array only has one element or empty
    if (unsortedArray.length <= 1) {
      return unsortedArray;
    }
    // In order to divide the array in half, we need to figure out the middle
    const middle = Math.floor(unsortedArray.length / 2);
  
    // This is where we will be dividing the array into left and right
    const left = unsortedArray.slice(0, middle);
    const right = unsortedArray.slice(middle);
  
    // Using recursion to combine the left and right
    return merge(
      mergeSort(left, elementType),
      mergeSort(right, elementType),
      elementType
    );
  }
  
  module.exports = {
    mergeSort,
  };
  