import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import surveyListApp from './surveyList/reducer';
import surveyDetailApp from './surveyDetail/reducer';
import authData from "./auth/reducer";
import brand from "./brand/reducer";
import userData from "./user/reducer";
import influencer from "./Influencer/reducer";
import admin from "./admin/reducer";

const reducers = combineReducers({
  menu,
  settings,
  surveyListApp,
  surveyDetailApp,
  authData,
  userData,
  brand,
  influencer,
  admin
});

export default reducers;