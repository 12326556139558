import {extractMentions} from './mention';

const copyProps = (src, tar, props) => {
    props.forEach(prop => tar[prop] = src[prop]);
}


export const extractPostDetails = (mainData) => {
    const details = {};
    
    const propsToCopy = ['shortcode', '__typename', 'id', 'is_video', 'has_audio', 'video_url', 'video_view_count', 'display_url'];
    copyProps(mainData, details, propsToCopy);
    details['timestamp'] = mainData['taken_at_timestamp'];
    const captionEdges = mainData['edge_media_to_caption']['edges'];
    details['caption'] = captionEdges.length > 0 ? captionEdges[0]['node']['text'] : '';

    // comment
    const comment_details = {}
    comment_details['count'] = mainData['edge_media_to_comment']['count']
    details['comment_count'] = comment_details['count'];

    // like
    details['like_count'] = mainData['edge_media_preview_like']['count'];

    // owner
    const owner_details = {};
    const owner = mainData['owner'];
    copyProps(owner, owner_details, ['username']);
    details['owner'] = owner_details;

    // mentions
    details['mentions'] = extractMentions(details['caption']);

    return details;
}

export const extractUserDetails = (mainData) => {
    const user = mainData.graphql.user;    
    const details = {}

    let f = 'logging_page_id';
    details[f] = mainData[f]

    const propsToCopy = ['biography', 'is_business_account', 'is_private', 'is_verified', 'username', 'connected_to_fb_page', 'profile_pic_url', 'profile_pic_url_hd']
    copyProps(user, details, propsToCopy);
    details['followers_count'] = user['edge_followed_by']['count'];
    details['follows_count'] = user['edge_follow']['count'];
    details['name'] = user['full_name'];
    
    // timeline media
    f = 'edge_owner_to_timeline_media';
    details['media_count'] = user[f]['count']
    // details['timeline_media'] = user[f]['edges'].map(edge => extractPostDetails(edge.node));

    return details;
}

