// const apiURL = "http://api.madinfluence.thecodebucket.com/graphql";
const env = process.env.REACT_APP_ENV
export const apiURL = env === 'development' ? 'http://localhost:7000/graphql' : 'http://156.67.221.249:7000/graphql';
console.log("app env:", env);
export const capitalizeStr = (str) =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const setItemInSession = (key, value) => {
  localStorage.setItem(key, value);
};

export const getItemFromSession = (key) => {
  return localStorage.getItem(key);
};

export const removeItemFromSession = (key) => {
  localStorage.removeItem(key);
};

export const fetchRes = async (requestBody) => {
  const token = getItemFromSession("token");
  const res = await fetch(apiURL, {
    method: "POST",
    body: JSON.stringify(requestBody),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  const resData = await res.json();
  return resData;
};

export const removeEmptyProperties = (obj) => {
  const newObj = {};
  const keys = Object.keys(obj);
  keys.forEach((key) => {
    if (isEmpty(obj[key])) return;
    newObj[key] = obj[key];
  });
  return newObj;
};

export const isEmpty = (value) => {
  if (
    value === 0 ||
    (typeof value === "string" && value.trim() !== "") ||
    value
  ) {
    return false;
  }

  return true;
};
