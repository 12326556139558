import * as utils from '../utils';
import * as igConstants from './constants';
import {extractUserDetails, extractPostDetails} from './mainDataUtil';
import {formatUserVG} from './format';

export const getUserResData = async (username) => {
    console.log(`Getting  user details of ${username}...`);
    const url = `${igConstants.baseUrl}/${username}/?__a=1`;
    const res = await utils.req.get(url);
    const resData = await res.json();
    
    return resData;
}

const getBasicFields = (userResData) => {
    const user = extractUserDetails(userResData);
    return user;
}

const getTimelineMedia = async (id, query_id) => {
    const url = `${igConstants.baseUrl}/graphql/query/?query_id=${query_id}&id=${id}&first=50`;
    const res = await utils.req.get(url);
    const resData = await res.json();
    const edges = resData['data']['user']['edge_owner_to_timeline_media']['edges'];
    const timelineMedia = edges.map(edge => extractPostDetails(edge.node));
    return timelineMedia;
}

export const get = async (username) => {
    // Check that username is a non empty str
    if (!utils.str.isStr(username) || utils.str.isEmptyStr(username)) {
        throw new Error("Invalid username");
    }

    // fetch res data
    const userResData = await getUserResData(username);

    // basic fields
    const user = getBasicFields(userResData);

    // timeline media
    let timelineMedia;
    if (!user.is_pivate) {
        timelineMedia = await getTimelineMedia(user.logging_page_id.slice(12), igConstants.timeline_media_query_id);
    }
    user['timeline_media'] = timelineMedia;

    // format the response
    formatUserVG(user);
    
    // return
    return user;
}
