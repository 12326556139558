import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  LOGIN_ADMIN,
  VERIFY_OTP,
  verifyOtpError,
  resendOtpError,
  resendOtpSuccess,
  RESEND_OTP,
  FINAL_REGISTER,
  finalRegisterSuccess, finalRegisterError,
} from "../actions";

import {
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError, verifyOtpSuccess,
} from "./actions";

import { setItemInSession, removeItemFromSession, fetchRes } from "../helpers";
import * as reqBody from "../requestBody";

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginFunc);
}

export function* watchLoginAdmin() {
  yield takeEvery(LOGIN_ADMIN, loginAdminFunc);
}

export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, registerFunc);
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logoutFunc);
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

export function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

export function* watchVerifyOtp() {
  yield takeEvery(VERIFY_OTP, verifyOtp);
}

function* verifyOtp({payload}) {
  const { history, otp } = payload;
  let resData = yield call(verifyOtpAsync, otp, localStorage.getItem("userId"));
  if (!resData.errors) {
    const otpData = resData.data.verifyEmail;
    if(otpData.status == "ok") {
      yield put(verifyOtpSuccess());
      history.push('/');
    } else {
      yield put(verifyOtpError(otpData.description));
    }
  }
}

async function verifyOtpAsync(otp,user_id) {
  otp = Number(otp);
  const requestBody = reqBody.userEmailVerificationBody(otp,user_id);
  const resData = await fetchRes(requestBody);
  return resData;
}


export function* watchResendOtp() {
  yield takeEvery(RESEND_OTP, resendOtp);
}

function* resendOtp() {
  let resData = yield call(resendOtpAsync, localStorage.getItem("userId"));
  if (!resData.errors) {
    const otpData = resData.data.resendOtp;
    if(otpData.status == "ok") {
      yield put(resendOtpSuccess("success"))
    } else {
      yield put(resendOtpError(otpData.description));
    }
  }
}

async function resendOtpAsync(user_id) {
  const requestBody = reqBody.resendOtpBody(user_id);
  const resData = await fetchRes(requestBody);
  return resData;
}

function* loginFunc({ payload }) {
  const { history, userInput } = payload;
  const { email, password, userType } = userInput;
  try {
    let resData = yield call(loginAsync, email, password, userType);
    if (!resData.errors) {
      const authData = resData.data.login;
      const { token, userId, username } = authData;
      if(!authData.error) {
        setItemInSession("token", token);
        setItemInSession("userType", userType);
        setItemInSession("userId", userId);
        setItemInSession("username", username);
        yield put(loginUserSuccess(userType, userId));
        history.push(`/${userType}`);
      } else {
        yield put(loginUserError(authData.error.message));
      }
    } else {
      throw new Error("Failed to log in user!");
    }
  } catch (error) {
    yield put(loginUserError(error.message));
    history.push("/");
  }
}

async function loginAsync(email, password, userType) {
  const requestBody = reqBody.getLoginBody(email, password, userType);

  const resData = await fetchRes(requestBody);
  return resData;
}

async function loginAdminAsync(username, password) {
  const requestBody = reqBody.getLoginAdminBody(username, password);
  const resData = await fetchRes(requestBody);
  return resData;
}

function* loginAdminFunc({ payload }) {
  const { history, userInput } = payload;
  const { username, password} = userInput;
  try {
    let resData = yield call(loginAdminAsync, username, password);
    if (!resData.errors) {
      const authData = resData.data.loginAdmin;
      const { token, userId } = authData;

      if(!authData.error) {
        setItemInSession("token", token);
        setItemInSession("userType", "admin");
        setItemInSession("userId", userId);
        setItemInSession("username", "admin");
        yield put(loginUserSuccess("admin", userId));
        history.push(`/admin`);
      } else {
        yield put(loginUserError(authData.error.message));
      }
    } else {
      throw new Error("Failed to log in user!");
    }
  } catch (error) {
    yield put(loginUserError(error.message));
    history.push("/");
  }
}


async function registerAsync(fullname, email, phone, userType) {
  const requestBody = reqBody.getRegisterBody(
    fullname,
    email,
    phone,
    userType
  );

  const resData = await fetchRes(requestBody);
  return resData;
}

function* registerFunc({ payload }) {
  const { history, userInput } = payload;
  const { fullname, email, phone, userType } = userInput;
  console.log("usr type of register func", userType);
  try {
    const resData = yield call(
      registerAsync,
      fullname,
      email,
      phone,
      userType
    );
    if (!resData.errors) {
      const user = resData.data.beginRegistration;
      if(user.status == "ok") {
        let data = JSON.parse(user.data);
        setItemInSession("userId", data.betaUser.id);
        yield put(registerUserSuccess(userType, data.betaUser.id));
      } else {
        if(user.description)
        yield put(registerUserError(user.description));
        if(user.error)
          yield put(registerUserError(user.error.message));
      }
    } else {
      throw new Error("Failed to register user!");
    }
  } catch (error) {
    yield put(registerUserError(error));
  }
}

function logoutFunc({ payload }) {
  const { history } = payload;
  try {
    removeItemFromSession("token");
    removeItemFromSession("userId");
    removeItemFromSession("userType");
    removeItemFromSession("username");
    history.push("/");
  } catch (error) {}
}

const forgotPasswordAsync = async (email) => {};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (!forgotPasswordStatus) {
      yield put(forgotPasswordSuccess("success"));
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
  //   return await auth
  //     .confirmPasswordReset(resetPasswordCode, newPassword)
  //     .then((user) => user)
  //     .catch((error) => error);
};

function* resetPassword({ payload }) {
  const { newPassword, resetPasswordCode } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      resetPasswordCode,
      newPassword
    );
    if (!resetPasswordStatus) {
      yield put(resetPasswordSuccess("success"));
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}


export function* watchFinalRegister() {
  yield takeEvery(FINAL_REGISTER, finalRegisterFunc);
}

function* finalRegisterFunc({payload}) {
  const { history,betaUserId, password } = payload;
  let resData = yield call(finalRegisterAsync, betaUserId, password);
  if (!resData.errors) {
    const authData = resData.data.register;
    const { token, userId } = authData;
    if(!authData.error) {
      setItemInSession("token", token);
      setItemInSession("userId", userId);
      yield put(finalRegisterSuccess("success"));
      history.push('/user/login')
    }
    else {
      yield put(finalRegisterError(authData.error.message));
    }
  }
}

async function finalRegisterAsync(betaUserId, password) {
  const requestBody = reqBody.finalRegistration(betaUserId, password);
  const resData = await fetchRes(requestBody);
  return resData;
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchRegisterUser),
    fork(watchLogoutUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
    fork(watchLoginAdmin),
    fork(watchVerifyOtp),
    fork(watchResendOtp),
    fork(watchFinalRegister)
  ]);
}
