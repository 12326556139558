import {
    GET_CAMPAIGNS_FOR_ADMIN,
    GET_BRANDS_FOR_ADMIN,
    GET_INFLUENCERS_FOR_ADMIN,
    GET_INFLUENCERS_FOR_ADMIN_SUCCESS,
    GET_BRANDS_FOR_ADMIN_SUCCESS,
    GET_CAMPAIGNS_FOR_ADMIN_SUCCESS,
    GET_CAMPAIGN_FOR_ADMIN,
    GET_INFLUENCER_FOR_ADMIN,
    GET_BRAND_FOR_ADMIN,
    GET_INFLUENCER_FOR_ADMIN_SUCCESS,
    GET_BRAND_FOR_ADMIN_SUCCESS,
    GET_CAMPAIGN_FOR_ADMIN_SUCCESS,
    UPDATE_V_STATUS,
    UPDATE_V_STATUS_SUCCESS,
    UPDATE_V_STATUS_ERROR,
    UPDATE_CAMPAIGN_STATUS,
    UPDATE_CAMPAIGN_STATUS_SUCCESS,
    UPDATE_CAMPAIGN_STATUS_ERROR,
    UPDATE_INFLUENCER_ASSIGNMENT,
    UPDATE_INFLUENCER_ASSIGNMENT_SUCCESS,
    UPDATE_INFLUENCER_ASSIGNMENT_ERROR,
    DELETE_CAMPAIGN,
    DELETE_CAMPAIGN_SUCCESS,
    DELETE_CAMPAIGN_ERROR,
    DELETE_USER,
    DELETE_USER_SUCCESS,
    DELETE_USER_ERROR,
    GET_CAMPAIGNS_FOR_ADMIN_ERROR,
    GET_INFLUENCERS_FOR_ADMIN_ERROR,
    GET_BRANDS_FOR_ADMIN_ERROR,
    GET_CAMPAIGN_FOR_ADMIN_ERROR,
    GET_INFLUENCER_FOR_ADMIN_ERROR,
    GET_BRAND_FOR_ADMIN_ERROR,
    GET_COUNTS,
    GET_COUNTS_SUCCESS,
    GET_COUNTS_ERROR,
    GET_GROUPED_COUNTS,
    GET_GROUPED_COUNTS_SUCCESS,
    GET_GROUPED_COUNTS_ERROR,
    GET_ACCOUNT_ANALYZER,
    GET_ACCOUNT_ANALYZER_SUCCESS,
    GET_ACCOUNT_ANALYZER_ERROR,
    GET_POST_ANALYZER,
    GET_POST_ANALYZER_SUCCESS,
    GET_POST_ANALYZER_ERROR,
    GET_BETA_USERS,
    GET_BETA_USERS_SUCCESS,
    GET_BETA_USERS_ERROR, SET_BETA_USER, SET_BETA_USER_SUCCESS, SET_BETA_USER_ERROR,
    GET_IG_USER_ANALYSIS, GET_IG_USER_ANALYSIS_SUCCESS, GET_IG_USER_ANALYSIS_ERROR,
    GET_IG_USER_ANALYSIS_CLIENT, GET_IG_USER_ANALYSIS_CLIENT_SUCCESS, GET_IG_USER_ANALYSIS_CLIENT_ERROR
} from "../actions";
import moment from "moment";

const INIT_STATE = {
    campaigns: [],
    brands: [],
    influencers: [],
    campaign:{},
    influencer:{},
    brand:{},
    counts:{},
    account_analyzer_data:"",
    post_analyzer_data:"",
    grouped_counts: "",
    beta_users:[],
    changed:"",
    loading: false,
    campaign_type:"",
    campaign_v_type:"",
    brand_type:"",
    ig_user_analysis: "",
    error:""
};

function sortByPostDate(arr) {
    return arr.sort((a,b)=>{
        if(!a.post_date)
            return 1;
        if(!b.post_date)
            return -1;

        let diff = moment(b.post_date,'LL')- moment(a.post_date,'LL');
        if(diff===0)
            return b.id-a.id;
        return moment(b.post_date,'LL')- moment(a.post_date,'LL');
    })
}

function sortById(arr) {
    return arr.sort((a,b)=>{
        return Number(b.id)-Number(a.id);
    });
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_CAMPAIGNS_FOR_ADMIN:
            return {...state,campaigns: [],
                campaign_type: action.payload.filter.status?action.payload.filter.status:"",
                campaign_v_type: action.payload.filter.v_status?action.payload.filter.v_status:"",
                loading:true, error:""};

        case GET_CAMPAIGNS_FOR_ADMIN_SUCCESS:
            return {...state,campaigns: sortByPostDate(action.payload.campaigns),
                loading: false,error:""};

        case GET_CAMPAIGNS_FOR_ADMIN_ERROR:
            return {...state,campaigns: [],
                loading: false,error:action.payload.message};


        case GET_INFLUENCERS_FOR_ADMIN:
            return {...state,influencers: [], loading:true, error:""};
        case GET_INFLUENCERS_FOR_ADMIN_SUCCESS:
            return {...state,influencers: sortById(action.payload.influencers),
                loading: false,error:""};
        case GET_INFLUENCERS_FOR_ADMIN_ERROR:
            return {...state,influencers: [],
                loading: false,error: action.payload.message};

        case GET_BRANDS_FOR_ADMIN:
            return {...state,brand_type: action.payload.filter.v_status,brands: [], loading:true, error:""};
        case GET_BRANDS_FOR_ADMIN_SUCCESS:
            return {...state,brands: sortById(action.payload.brands),
                loading: false,error:""};
        case GET_BRANDS_FOR_ADMIN_ERROR:
            return {...state,brands: [],
                loading: false,error:action.payload.message};

        case GET_CAMPAIGN_FOR_ADMIN:
            return {...state,campaign: {},
                loading:true, error:""};
        case GET_CAMPAIGN_FOR_ADMIN_SUCCESS:
            return {...state,campaign: action.payload.campaign,
                loading: false,error:""};
        case GET_CAMPAIGN_FOR_ADMIN_ERROR:
            return {...state,campaign: {},
                loading: false,error:action.payload.message};

        case GET_INFLUENCER_FOR_ADMIN:
            return {...state,influencer: {}, loading:true, error:""};
        case GET_INFLUENCER_FOR_ADMIN_SUCCESS:
            return {...state,influencer: action.payload.influencer,
                loading: false,error:""};
        case GET_INFLUENCER_FOR_ADMIN_ERROR:
            return {...state,influencer: {},
                loading: false,error:action.payload.message};

        case GET_BRAND_FOR_ADMIN:
            return {...state,brand: {}, loading:true, error:""};
        case GET_BRAND_FOR_ADMIN_SUCCESS:
            return {...state,brand: action.payload.brand,
                loading: false,error:""};
        case GET_BRAND_FOR_ADMIN_ERROR:
            return {...state,brand: {},
                loading: false,error:action.payload.message};


        case UPDATE_V_STATUS:
            return {...state,loading: true,error:"", changed:""};
        case UPDATE_V_STATUS_SUCCESS:
            return {...state,loading: false,error:"", changed:"changed"};
        case UPDATE_V_STATUS_ERROR:
            return {...state,loading: false,error:action.payload.message, changed:""};

        case UPDATE_CAMPAIGN_STATUS:
            return {...state,loading: true,error:"", changed:""};
        case UPDATE_CAMPAIGN_STATUS_SUCCESS:
            return {...state,loading: false,error:"", changed:"changed"};
        case UPDATE_CAMPAIGN_STATUS_ERROR:
            return {...state,loading: false,error:action.payload.message, changed:""};

        case UPDATE_INFLUENCER_ASSIGNMENT:
            return {...state,loading: true,error:"", changed:""};
        case UPDATE_INFLUENCER_ASSIGNMENT_SUCCESS:
            return {...state,loading: false,error:"", changed:"changed"};
        case UPDATE_INFLUENCER_ASSIGNMENT_ERROR:
            return {...state,loading: false,error:action.payload.message, changed:""};

        case DELETE_CAMPAIGN:
            return {...state,loading: true,error:"", changed:""};
        case DELETE_CAMPAIGN_SUCCESS:
            return {...state,loading: false,error:"", changed:"deleted"};
        case DELETE_CAMPAIGN_ERROR:
            return {...state,loading: false,error:action.payload.message, changed:""};

        case DELETE_USER:
            return {...state,loading: true,error:"", changed:""};
        case DELETE_USER_SUCCESS:
            return {...state,loading: false,error:"", changed:"deleted"};
        case DELETE_USER_ERROR:
            return {...state,loading: false,error:action.payload.message, changed:""};


        case GET_COUNTS:
            return {...state,loading: true,error:"", counts:{}};
        case GET_COUNTS_SUCCESS:
            return {...state,loading: false,error:"", counts:action.payload.counts};
        case GET_COUNTS_ERROR:
            return {...state,loading: false,error:action.payload.message, counts:{}};

        case GET_GROUPED_COUNTS:
            return {...state,loading: true,error:"", grouped_counts:""};
        case GET_GROUPED_COUNTS_SUCCESS:
            return {...state,loading: false,error:"", grouped_counts:action.payload.grouped_counts};
        case GET_GROUPED_COUNTS_ERROR:
            return {...state,loading: false,error:action.payload.message, counts:{}};

        case GET_ACCOUNT_ANALYZER:
            return {...state,loading: true,error:"", account_analyzer_data:""};
        case GET_ACCOUNT_ANALYZER_SUCCESS:
            return {...state,loading: false,error:"", account_analyzer_data:action.payload.data};
        case GET_ACCOUNT_ANALYZER_ERROR:
            return {...state,loading: false,error:action.payload.message, account_analyzer_data:""};

        case GET_POST_ANALYZER:
            return {...state,loading: true,error:"", post_analyzer_data:""};
        case GET_POST_ANALYZER_SUCCESS:
            return {...state,loading: false,error:"", post_analyzer_data:action.payload.data};
        case GET_POST_ANALYZER_ERROR:
            return {...state,loading: false,error:action.payload.message, post_analyzer_data:""};

        case GET_BETA_USERS:
            return {...state,loading: true,error:"", beta_users:""};
        case GET_BETA_USERS_SUCCESS:
            return {...state,loading: false,error:"", beta_users:JSON.parse(action.payload.data)};
        case GET_BETA_USERS_ERROR:
            return {...state,loading: false,error:action.payload.message, beta_users:""};

        case SET_BETA_USER:
            return {...state,loading: true,error:""};
        case SET_BETA_USER_SUCCESS:
            return {...state,loading: false,error:"", changed: "beta_user"};
        case SET_BETA_USER_ERROR:
            return {...state,loading: false,error:action.payload.message};

        case GET_IG_USER_ANALYSIS:
            return {...state,loading: true,error:""};
        case GET_IG_USER_ANALYSIS_SUCCESS:
            return {...state,loading: false,error:"", ig_user_analysis: JSON.parse(action.payload.data)};
        case GET_IG_USER_ANALYSIS_ERROR:
            return {...state,loading: false,error:action.payload.message, ig_user_analysis: ""};

        case GET_IG_USER_ANALYSIS_CLIENT:
            return {...state, loading: true, error: ""};
        case GET_IG_USER_ANALYSIS_CLIENT_SUCCESS:
            return {...state,loading: false,error:"", ig_user_analysis: action.payload.data};
        case GET_IG_USER_ANALYSIS_CLIENT_ERROR:
            return {...state,loading: false,error:action.payload.message, ig_user_analysis: ""};

        default: return {...state};
    }
};
