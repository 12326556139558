/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";


/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_ADMIN = "LOGIN_ADMIN";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const VERIFY_OTP = "VERIFY_OTP";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_ERROR = "VERIFY_OTP_ERROR";
export const RESEND_OTP = "RESEND_OTP";
export const RESEND_OTP_SUCCESS = "RESEND_OTP_SUCCESS";
export const RESEND_OTP_ERROR = "RESEND_OTP_ERROR";
export const FINAL_REGISTER = "FINAL_REGISTER";
export const FINAL_REGISTER_SUCCESS = "FINAL_REGISTER_SUCCESS";
export const FINAL_REGISTER_ERROR = "FINAL_REGISTER_ERROR";

/* USER */
export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_ERROR = "GET_USER_ERROR";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";
export const TOGGLE_EDIT_MODE = "TOGGLE_EDIT_MODE";


/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  "MENU_CHANGE_HAS_SUB_ITEM_STATUS";

export const SURVEY_LIST_GET_LIST = "SURVEY_LIST_GET_LIST";
export const SURVEY_LIST_GET_LIST_SUCCESS = "SURVEY_LIST_GET_LIST_SUCCESS";
export const SURVEY_LIST_GET_LIST_ERROR = "SURVEY_LIST_GET_LIST_ERROR";
export const SURVEY_LIST_GET_LIST_WITH_FILTER =
    "SURVEY_LIST_GET_LIST_WITH_FILTER";
export const SURVEY_LIST_GET_LIST_WITH_ORDER =
    "SURVEY_LIST_GET_LIST_WITH_ORDER";
export const SURVEY_LIST_GET_LIST_SEARCH = "SURVEY_LIST_GET_LIST_SEARCH";
export const SURVEY_LIST_ADD_ITEM = "SURVEY_LIST_ADD_ITEM";
export const SURVEY_LIST_ADD_ITEM_SUCCESS = "SURVEY_LIST_ADD_ITEM_SUCCESS";
export const SURVEY_LIST_ADD_ITEM_ERROR = "SURVEY_LIST_ADD_ITEM_ERROR";
export const SURVEY_LIST_SELECTED_ITEMS_CHANGE =
    "SURVEY_LIST_SELECTED_ITEMS_CHANGE";

/* SURVEY DETAIL APP*/
export const SURVEY_GET_DETAILS = "SURVEY_GET_DETAILS";
export const SURVEY_GET_DETAILS_SUCCESS = "SURVEY_GET_DETAILS_SUCCESS";
export const SURVEY_GET_DETAILS_ERROR = "SURVEY_GET_DETAILS_ERROR";
export const SURVEY_DELETE_QUESTION = "SURVEY_DELETE_QUESTION";
export const SURVEY_SAVE = "SURVEY_SAVE";


/* Brand */
export const CREATE_CAMPAIGN = "CREATE_CAMPAIGN";
export const CREATE_CAMPAIGN_SUCCESS = "CREATE_CAMPAIGN_SUCCESS";
export const CREATE_CAMPAIGN_ERROR = "CREATE_CAMPAIGN_ERROR";
export const GET_CAMPAIGNS_OF_BRAND = "GET_CAMPAIGNS_OF_BRAND";
export const GET_CAMPAIGNS_OF_BRAND_SUCCESS = "GET_CAMPAIGNS_OF_BRAND_SUCCESS";
export const GET_CAMPAIGNS_OF_BRAND_ERROR = "GET_CAMPAIGNS_OF_BRAND_ERROR";
export const GET_INFLUENCERS = "GET_INFLUENCERS";
export const GET_INFLUENCERS_SUCCESS = "GET_INFLUENCERS_SUCCESS";
export const GET_INFLUENCERS_ERROR = "GET_INFLUENCERS_ERROR";
export const CREATE_INVITATION = "CREATE_INVITATION";
export const CREATE_INVITATION_SUCCESS = "CREATE_INVITATION_SUCCESS";
export const CREATE_INVITATION_ERROR = "CREATE_INVITATION_ERROR";
export const GET_CAMPAIGN = "GET_CAMPAIGN";
export const GET_CAMPAIGN_SUCCESS = "GET_CAMPAIGN_SUCCESS";
export const GET_CAMPAIGN_ERROR = "GET_CAMPAIGN_ERROR";
export const UPDATE_CAMPAIGN = "UPDATE_CAMPAIGN";
export const UPDATE_CAMPAIGN_SUCCESS = "UPDATE_CAMPAIGN_SUCCESS ";
export const UPDATE_CAMPAIGN_ERROR = "UPDATE_CAMPAIGN_ERROR";
export const CHECK_DRAFT = "CHECK_DRAFT";
export const CHECK_DRAFT_SUCCESS = "CHECK_DRAFT_SUCCESS ";
export const CHECK_DRAFT_ERROR = "CHECK_DRAFT_ERROR";
export const COMPARE_INFS = "COMPARE_INFS";
export const COMPARE_INFS_SUCCESS = "COMPARE_INFS_SUCCESS ";
export const COMPARE_INFS_ERROR = "COMPARE_INFS_ERROR";
export const GET_ACCOUNT_ANALYZER = "GET_ACCOUNT_ANALYZER";
export const GET_ACCOUNT_ANALYZER_SUCCESS = "GET_ACCOUNT_ANALYZER_SUCCESS";
export const GET_ACCOUNT_ANALYZER_ERROR = "GET_ACCOUNT_ANALYZER_ERROR";
export const GET_POST_ANALYZER = "GET_POST_ANALYZER";
export const GET_POST_ANALYZER_SUCCESS = "GET_POST_ANALYZER_SUCCESS";
export const GET_POST_ANALYZER_ERROR = "GET_POST_ANALYZER_ERROR";

/* Influencer */
export const GET_CAMPAIGNS_FOR_INFLUENCER = "GET_CAMPAIGNS_FOR_INFLUENCER";
export const GET_CAMPAIGNS_FOR_INFLUENCER_SUCCESS =
    "GET_CAMPAIGNS_FOR_INFLUENCER_SUCCESS";
export const GET_CAMPAIGNS_FOR_INFLUENCER_ERROR =
    "GET_CAMPAIGNS_FOR_INFLUENCER_ERROR";
export const CREATE_BID = "CREATE_BID";
export const CREATE_BID_SUCCESS = "CREATE_BID_SUCCESS";
export const CREATE_BID_ERROR = "CREATE_BID_ERROR";
export const ADD_LINK = "ADD_LINK";
export const ADD_LINK_SUCCESS = "ADD_LINK_SUCCESS";
export const ADD_LINK_ERROR = "ADD_LINK_ERROR";
export const SAVE_ACCESS_TOKEN = "SAVE_ACCESS_TOKEN";
export const SAVE_ACCESS_TOKEN_SUCCESS = "SAVE_ACCESS_TOKEN_SUCCESS";
export const SAVE_ACCESS_TOKEN_ERROR = "SAVE_ACCESS_TOKEN_ERROR";

/* Admin */
export const GET_INFLUENCERS_FOR_ADMIN = "GET_INFLUENCERS_FOR_ADMIN";
export const GET_BRANDS_FOR_ADMIN = "GET_BRANDS_FOR_ADMIN";
export const GET_CAMPAIGNS_FOR_ADMIN = "GET_CAMPAIGNS_FOR_ADMIN";
export const GET_INFLUENCERS_FOR_ADMIN_SUCCESS = "GET_INFLUENCERS_FOR_ADMIN_SUCCESS";
export const GET_BRANDS_FOR_ADMIN_SUCCESS = "GET_BRANDS_FOR_ADMIN_SUCCESS";
export const GET_CAMPAIGNS_FOR_ADMIN_SUCCESS = "GET_CAMPAIGNS_FOR_ADMIN_SUCCESS";
export const GET_INFLUENCERS_FOR_ADMIN_ERROR = "GET_INFLUENCERS_FOR_ADMIN_ERROR";
export const GET_BRANDS_FOR_ADMIN_ERROR = "GET_BRANDS_FOR_ADMIN_ERROR";
export const GET_CAMPAIGNS_FOR_ADMIN_ERROR = "GET_CAMPAIGNS_FOR_ADMIN_ERROR";
export const GET_INFLUENCER_FOR_ADMIN = "GET_INFLUENCER_FOR_ADMIN";
export const GET_BRAND_FOR_ADMIN = "GET_BRAND_FOR_ADMIN";
export const GET_CAMPAIGN_FOR_ADMIN = "GET_CAMPAIGN_FOR_ADMIN";
export const GET_INFLUENCER_FOR_ADMIN_SUCCESS = "GET_INFLUENCER_FOR_ADMIN_SUCCESS";
export const GET_BRAND_FOR_ADMIN_SUCCESS = "GET_BRAND_FOR_ADMIN_SUCCESS";
export const GET_CAMPAIGN_FOR_ADMIN_SUCCESS = "GET_CAMPAIGN_FOR_ADMIN_SUCCESS";
export const GET_INFLUENCER_FOR_ADMIN_ERROR = "GET_INFLUENCER_FOR_ADMIN_ERROR";
export const GET_BRAND_FOR_ADMIN_ERROR = "GET_BRAND_FOR_ADMIN_ERROR";
export const GET_CAMPAIGN_FOR_ADMIN_ERROR = "GET_CAMPAIGN_FOR_ADMIN_ERROR";
export const UPDATE_V_STATUS = "UPDATE_V_STATUS";
export const UPDATE_V_STATUS_SUCCESS = "UPDATE_V_STATUS_SUCCESS";
export const UPDATE_V_STATUS_ERROR = "UPDATE_V_STATUS_ERROR";
export const UPDATE_CAMPAIGN_STATUS = "UPDATE_CAMPAIGN_STATUS";
export const UPDATE_CAMPAIGN_STATUS_SUCCESS = "UPDATE_CAMPAIGN_STATUS_SUCCESS";
export const UPDATE_CAMPAIGN_STATUS_ERROR = "UPDATE_CAMPAIGN_STATUS_ERROR";
export const UPDATE_INFLUENCER_ASSIGNMENT = "UPDATE_INFLUENCER_ASSIGNMENT";
export const UPDATE_INFLUENCER_ASSIGNMENT_SUCCESS = "UPDATE_INFLUENCER_ASSIGNMENT_SUCCESS";
export const UPDATE_INFLUENCER_ASSIGNMENT_ERROR = "UPDATE_INFLUENCER_ASSIGNMENT_ERROR";
export const DELETE_CAMPAIGN = "DELETE_CAMPAIGN";
export const DELETE_CAMPAIGN_SUCCESS = "DELETE_CAMPAIGN_SUCCESS";
export const DELETE_CAMPAIGN_ERROR = "DELETE_CAMPAIGN_ERROR";
export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_ERROR = "DELETE_USER_ERROR";
export const GET_COUNTS="GET_COUNTS";
export const GET_COUNTS_SUCCESS="GET_COUNTS_SUCCESS";
export const GET_COUNTS_ERROR="GET_COUNTS_ERROR";
export const GET_GROUPED_COUNTS="GET_GROUPED_COUNTS";
export const GET_GROUPED_COUNTS_SUCCESS="GET_GROUPED_COUNTS_SUCCESS";
export const GET_GROUPED_COUNTS_ERROR="GET_GROUPED_COUNTS_ERROR";
export const GET_DRAFT_CAMPAIGN_DATA= "GET_DRAFT_CAMPAIGN_DATA";
export const GET_DRAFT_CAMPAIGN_DATA_SUCCESS = "GET_DRAFT_CAMPAIGN_DATA_SUCCESS";
export const GET_DRAFT_CAMPAIGN_DATA_ERROR= "GET_DRAFT_CAMPAIGN_DATA_ERROR";
export const GET_BETA_USERS = "GET_BETA_USERS";
export const GET_BETA_USERS_SUCCESS = "GET_BETA_USERS_SUCCESS";
export const GET_BETA_USERS_ERROR = "GET_BETA_USERS_ERROR";
export const SET_BETA_USER = "SET_BETA_USER";
export const SET_BETA_USER_SUCCESS = "SET_BETA_USER_SUCCESS";
export const SET_BETA_USER_ERROR = "SET_BETA_USER_ERROR";
export const GET_IG_USER_ANALYSIS = "GET_IG_USER_ANALYSIS";
export const GET_IG_USER_ANALYSIS_SUCCESS = "GET_IG_USER_ANALYSIS_SUCCESS";
export const GET_IG_USER_ANALYSIS_ERROR = "GET_IG_USER_ANALYSIS_ERROR";
export const GET_IG_USER_ANALYSIS_CLIENT = "GET_IG_USER_ANALYSIS_CLIENT";
export const GET_IG_USER_ANALYSIS_CLIENT_SUCCESS = "GET_IG_USER_ANALYSIS_CLIENT_SUCCESS";
export const GET_IG_USER_ANALYSIS_CLIENT_ERROR = "GET_IG_USER_ANALYSIS_CLIENT_ERROR";

export const RESPOND_TO_REQUESTED_RATE = "RESPOND_TO_REQUESTED_RATE";
export const RESPOND_TO_REQUESTED_RATE_SUCCESS = "RESPOND_TO_REQUESTED_RATE_SUCCESS";
export const RESPOND_TO_REQUESTED_RATE_ERROR = "RESPOND_TO_REQUESTED_RATE_ERROR";

export const RESPOND_TO_REQUESTED_RATE_BRAND = "RESPOND_TO_REQUESTED_RATE_BRAND";
export const RESPOND_TO_REQUESTED_RATE_SUCCESS_BRAND = "RESPOND_TO_REQUESTED_RATE_SUCCESS_BRAND";
export const RESPOND_TO_REQUESTED_RATE_ERROR_BRAND = "RESPOND_TO_REQUESTED_RATE_ERROR_BRAND";


export * from "./auth/actions";
export * from "./menu/actions";
export * from "./settings/actions";
export * from "./surveyList/actions";
export * from "./surveyDetail/actions";
export * from "./brand/actions";
export * from "./Influencer/actions";
export * from "./admin/actions";