import stopwords from './stopwords';

const extractWords = (text) => {
    const regexp = /[a-zA-Z0-9_\-\.]*/g; // @ and then letters, digits, perios, underscore and * for matching 0 or more preceding tokens
    const matches = [...text.matchAll(regexp)];
    const words = matches.map(arr => arr[0]);
    return words;
}

const removeStopwords = (words) => {
    const impWords = [];
    for (const word of words) {
        if (!stopwords.includes(word.toLowerCase())) {
            impWords.push(word);
        }
    }
    
    return impWords;
}

export {removeStopwords, extractWords}