import {
  GET_CAMPAIGNS_FOR_INFLUENCER,
  GET_CAMPAIGNS_FOR_INFLUENCER_SUCCESS,
  GET_CAMPAIGNS_FOR_INFLUENCER_ERROR,
  CREATE_BID,
  CREATE_BID_SUCCESS,
  CREATE_BID_ERROR,
  ADD_LINK,
  ADD_LINK_SUCCESS,
  ADD_LINK_ERROR,
  RESPOND_TO_REQUESTED_RATE,
  RESPOND_TO_REQUESTED_RATE_SUCCESS,
  RESPOND_TO_REQUESTED_RATE_ERROR,
  SAVE_ACCESS_TOKEN, SAVE_ACCESS_TOKEN_ERROR, SAVE_ACCESS_TOKEN_SUCCESS,
} from "../actions";
import moment from "moment";

const INIT_STATE = {
  type:"",
  link:"",
  availableCampaigns: [],
  appliedCampaigns: [],
  activeCampaigns: [],
  completedCampaigns: [],
  invitedCampaigns:[],
  changed:"",
  loading: false,
  error: "",
};

function sortById(arr) {
  return arr.sort((a,b)=>{
    return Number(b.id)-Number(a.id);
  });
}

function sortByPostDate(arr) {
  return arr.sort((a,b)=>{
    if(!a.campaign.post_date)
      return 1;
    if(!b.campaign.post_date)
      return -1;

    let diff = moment(b.campaign.post_date,'LL')- moment(a.campaign.post_date,'LL');
    if(diff===0)
      return b.campaign.id-a.campaign.id;
    return moment(b.campaign.post_date,'LL')- moment(a.campaign.post_date,'LL');
  })
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CAMPAIGNS_FOR_INFLUENCER:
      return { ...state, loading: true, error: "",type:"" };
    case GET_CAMPAIGNS_FOR_INFLUENCER_SUCCESS:
      let { type, campaigns } = action.payload;
      campaigns = sortByPostDate(campaigns);
      let newState = { ...state, loading: false, error: "",type:type};
      if (type === "available") {
        newState.availableCampaigns = campaigns;
      }

      if (type === "applied") {
        newState.appliedCampaigns = campaigns;
      }

      if (type === "active") {
        newState.activeCampaigns = campaigns;
      }

      if (type === "completed") {
        newState.completedCampaigns = campaigns;
      }

      if (type === "invited") {
        newState.invitedCampaigns = campaigns;
      }
      return newState;
    case GET_CAMPAIGNS_FOR_INFLUENCER_ERROR:
      return {
        ...state,
        campaigns: [],
        loading: false,
        type:"",
        error: action.payload.message,
      };
    case CREATE_BID:
      return { ...state,changed:"", loading: true, error: "" };
    case CREATE_BID_SUCCESS:
      return {
        ...state,
        changed:"bid_added",
        loading: false,
        error: "",
      };
    case CREATE_BID_ERROR:
      return {
        ...state,
        changed:"",
        loading: false,
        error: action.payload.message,
      };
    case ADD_LINK:
      return { ...state,changed:"", loading: true, error: "" };
    case ADD_LINK_SUCCESS:
      return {
        ...state,
        changed:"link_added",
        link: action.payload.link,
        loading: false,
        error: "",
      };
    case ADD_LINK_ERROR:
      return {
        ...state,
        changed:"",
        link:"",
        loading: false,
        error: action.payload.message,
      };

    case RESPOND_TO_REQUESTED_RATE:
      return { ...state,changed:"", loading: true, error: "" };
    case RESPOND_TO_REQUESTED_RATE_SUCCESS:
      return {
        ...state,
        changed:"neg_added",
        loading: false,
        error: "",
      };
    case RESPOND_TO_REQUESTED_RATE_ERROR:
      return {
        ...state,
        changed:"",
        loading: false,
        error: action.payload.message,
      };

    case SAVE_ACCESS_TOKEN:
      return { ...state,changed:"", loading: true, error: "" };
    case SAVE_ACCESS_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case SAVE_ACCESS_TOKEN_ERROR:
      return {
        ...state,
        link:"",
        loading: false,
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};
