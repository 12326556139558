import {extractHashtags} from './hashtag';
import {mergeSort} from '../utils/sort';
import {extractWords, removeStopwords} from '../utils/word';

const formatUserVG = (user) => {
    console.log("formatter called")
    
    let topMentions = {};
    let topHashtags = {};
    const mediaType = {};
    let likeCount = 0;
    let commentCount = 0;
    let texts = [user['biography']];
    for (const post of user.timeline_media) {
        // top mentions
        for (const username of post['mentions']) {
            const curCount = topMentions[username];
            if (curCount) {
                topMentions[username] = curCount + 1;
            } else {
                topMentions[username] = 1;
            }
        }

        // top hashtags
        const hashtags = extractHashtags(post['caption']);
        for (const hashtag of hashtags) {
            const curCount = topHashtags[hashtag];
            if (curCount) {
                topHashtags[hashtag] = curCount + 1;
            } else {
                topHashtags[hashtag] = 1;
            }
        }

        // media type
        const mType = post['__typename'];
        const curCount = mediaType[mType];
        if (curCount) {
            mediaType[mType] = curCount + 1;
        } else {
            mediaType[mType] = 1;
        }

        // like count and comment count
        likeCount += post['like_count'];
        commentCount += post['comment_count'];
        
        // texts for top words
        texts.push(post['caption']);
    }

    topMentions = Object.entries(topMentions);
    topHashtags = Object.entries(topHashtags);
    topMentions = mergeSort(topMentions, 'top');
    topHashtags = mergeSort(topHashtags, 'top');
    
    let words = texts.reduce((accu, text) => [...accu, ...extractWords(text)], [])
    words = removeStopwords(words);

    let topWords = {};
    for (const word of words) {
        if (topWords.word) {
            topWords[word] = topWords[word] + 1;
        } else {
            topWords[word] = 1;
        }
    }

    topWords = mergeSort(Object.entries(topWords), 'top');

    let totalLikeCount;
    let totalCommentCount;
    const timelineMediaLen = user.timeline_media.length;
    const mediaCount = user['media_count'];
    if (timelineMediaLen === 0) {
        totalLikeCount = 0;
        totalCommentCount = 0;
    } else if (mediaCount > 50) {
        // Only fetched 50, so take average then multiply to get total likes
        totalLikeCount = (likeCount / timelineMediaLen) * mediaCount;
        totalCommentCount = (commentCount / timelineMediaLen) * mediaCount;
    } else {
        // Have fetched all media as it's less than 50
        totalLikeCount = likeCount;
        totalCommentCount = commentCount;
    }

    user['mentions'] = topMentions.slice(0, 5);
    user['hashtags'] = topHashtags.slice(0, 5);
    user['top_words'] = topWords;
    user['media_type'] = mediaType;
    user['like_count'] = totalLikeCount;
    user['comment_count'] = totalCommentCount;

    return user;
}

export {formatUserVG}