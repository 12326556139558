import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { GET_USER, UPDATE_USER } from "../actions";

import {
  getUserSuccess,
  getUserError,
  updateUserSuccess,
  updateUserError,
} from "./actions";

import { capitalizeStr, fetchRes } from "../helpers";
import * as reqBody from "../requestBody";

export function* watchGetUser() {
  yield takeEvery(GET_USER, getUserFunc);
}

export function* watchUpdateUser() {
  yield takeEvery(UPDATE_USER, updateUserFunc);
}

function* getUserFunc({ payload }) {
  console.log("get user payload", payload);
  console.log("get user saga");
  let userType = localStorage.getItem("userType");
  try {
    const resData = yield call(getUserAsync, userType);
    console.log("received response data", resData);

    if(resData.data) {
      let data = resData.data;
      if(!data.error) {
        if(userType==="brand") {
          const user = data[`getOneBrand`];
          if(user.error) {
            yield put(getUserError(user.error.message));
          }else
            yield put(getUserSuccess(user));
        }
        if(userType==="influencer") {
          const user = data[`getOneInfluencer`];
          console.log("yes influencer")
          if(user.error) {
            yield put(getUserError(user.error.message));
          } else
            yield put(getUserSuccess(user));
        }
      } else {
        yield put(getUserError(data.error.message));
      }
    } else {
      console.log("yes got errors");
      throw new Error("Could not get user data!");
    }
  } catch (error) {
    console.log("caught error");
    yield put(getUserError(error.message));
  }
}

async function getUserAsync(userType) {
  let requestBody;

  if (userType === "brand") {
    let userId = localStorage.getItem("userId");
    requestBody = reqBody.getBrandBody(userId);
  }

  if (userType === "influencer") {
    let userId = localStorage.getItem("userId");
    requestBody = reqBody.getInfluencerBody(userId);
  }

  if (!requestBody) {
    throw new Error("Failed to get user, invalid user type!");
  }

  const resData = await fetchRes(requestBody);
  return resData;
}

function* updateUserFunc({ payload }) {
  console.log("start update user func", "USERTYPE", payload.userType);
  const { updateUserInput } = payload;
  let userType = localStorage.getItem("userType");
  try {
    const resData = yield call(updateUserAsync, updateUserInput, userType);
    if (!resData.errors) {
      console.log("no errors");
      const user = resData.data[`update${capitalizeStr(userType)}`];
      if(user.error) {
        yield put(updateUserError(user.error.message));
      } else
        yield put(updateUserSuccess(user));
    } else {
      throw new Error("Failed to update user data!");
    }
  } catch (error) {
    console.log("got errors");
    console.log(error);
    yield put(updateUserError(error));
  }
}

async function updateUserAsync(updateUserInput, userType) {
  let requestBody;
  if (userType === "brand") {
    console.log("usertype is brand");
    requestBody = reqBody.getUpdateBrandBody(updateUserInput);
  }

  if (userType === "influencer") {
    console.log("usertype is influencer");
    requestBody = reqBody.getUpdateInfluencerBody(updateUserInput);
  }

  if (!requestBody) {
    throw new Error("Invalid user type!");
  }

  const resData = await fetchRes(requestBody);
  return resData;
}

export default function* rootSaga() {
  yield all([fork(watchGetUser), fork(watchUpdateUser)]);
}
