import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
    deleteCampaignError,
    deleteCampaignSuccess,
    deleteUserError,
    deleteUserSuccess,
    getAccountAnalyzerError,
    getAccountAnalyzerSuccess, getBetaUsersError, getBetaUsersSuccess,
    getBrandForAdminError,
    getBrandForAdminSuccess,
    getBrandsForAdminError,
    getBrandsForAdminSuccess,
    getCampaignForAdminError,
    getCampaignForAdminSuccess,
    getCampaignsForAdminError,
    getCampaignsForAdminSuccess,
    getCountsError,
    getCountsSuccess,
    getGroupedCountsError,
    getGroupedCountsSuccess,
    getInfluencerForAdminError,
    getInfluencerForAdminSuccess,
    getInfluencersForAdminError,
    getInfluencersForAdminSuccess,
    getPostAnalyzerError,
    getPostAnalyzerSuccess, setBetaUserError, setBetaUserSuccess,
    updateCampaignStatusError,
    updateCampaignStatusSuccess,
    updateVStatusError,
    updateVStatusSuccess,
    getIgUserAnalysisSuccess,
    getIgUserAnalysisError,
    getIgUserAnalysisClientSuccess,
    getIgUserAnalysisClientError
} from "./actions"

import {
    GET_CAMPAIGNS_FOR_ADMIN,
    GET_BRANDS_FOR_ADMIN,
    GET_INFLUENCERS_FOR_ADMIN,
    GET_CAMPAIGN_FOR_ADMIN,
    GET_BRAND_FOR_ADMIN,
    GET_INFLUENCER_FOR_ADMIN,
    UPDATE_V_STATUS,
    UPDATE_CAMPAIGN_STATUS,
    UPDATE_INFLUENCER_ASSIGNMENT,
    DELETE_CAMPAIGN,
    DELETE_USER,
    GET_COUNTS,
    GET_GROUPED_COUNTS,
    GET_POST_ANALYZER, GET_ACCOUNT_ANALYZER, GET_BETA_USERS, SET_BETA_USER,
    GET_IG_USER_ANALYSIS,
    GET_IG_USER_ANALYSIS_CLIENT
} from "../actions"

import * as igUser from '../../ig/user';

import {fetchRes, removeEmptyProperties } from "../helpers";
import * as reqBody from "../requestBody";


export function* watchGetInfluencers() {
    yield takeEvery(GET_INFLUENCERS_FOR_ADMIN, getInfluencers);
}

function* getInfluencers({ payload }) {
    const { filter } = payload;
    try {
        const resData = yield call(getInfluencersAsync, removeEmptyProperties(filter));
        if (!resData.errors) {
            let influencers = resData.data.getInfluencers.list;
            if(influencers.error) {
                let message = resData.data.getInfluencers.error.message;
                console.log(message);
                yield put(getInfluencersForAdminError(message));
            } else {
                yield put(getInfluencersForAdminSuccess(influencers));
            }
        } else {
            throw new Error(resData.errors[0].message);
        }
    } catch (error) {
        yield put(getInfluencersForAdminError(error.message));
    }
}

async function getInfluencersAsync(filter) {
    const requestBody = reqBody.getInfluencersForAdminBody(filter);
    const resData = await fetchRes(requestBody);
    return resData;
}

export function* watchGetBrands() {
    yield takeEvery(GET_BRANDS_FOR_ADMIN, getBrands);
}

function* getBrands({ payload }) {
    const { filter } = payload;
    try {
        const resData = yield call(getBrandsAsync, removeEmptyProperties(filter));
        if (!resData.errors) {
            let brands = resData.data.getBrands.list;
            if(resData.data.getBrands.error) {
                let message = resData.data.getBrands.error.message;
                yield put(getBrandsForAdminError(message));
            } else {
                yield put(getBrandsForAdminSuccess(brands));
            }
        } else {
            throw new Error(resData.errors[0].message);
        }
    } catch (error) {
        yield put(getBrandsForAdminError(error.message));
    }
}

async function getBrandsAsync(filter) {
    const requestBody = reqBody.getBrandsForAdminBody(filter);
    const resData = await fetchRes(requestBody);
    return resData;
}


export function* watchGetCampaigns() {
    yield takeEvery(GET_CAMPAIGNS_FOR_ADMIN, getCampaigns);
}

function* getCampaigns({ payload }) {
    const { filter } = payload;
    try {
        const resData = yield call(getCampaignsAsync, removeEmptyProperties(filter));
        if (!resData.errors) {
            let campaigns = resData.data.getCampaigns.list;
            if(resData.data.getCampaigns.error) {
                let message = resData.data.getCampaigns.error.message;
                yield put(getCampaignsForAdminError(message));
            } else {
                yield put(getCampaignsForAdminSuccess(campaigns));
            }
        } else {
            throw new Error(resData.errors[0].message);
        }
    } catch (error) {
        yield put(getCampaignsForAdminError(error.message));
    }
}

async function getCampaignsAsync(filter) {
    const requestBody = reqBody.getCampaignsForAdminBody(filter);
    const resData = await fetchRes(requestBody);
    return resData;
}


export function* watchGetCampaign() {
    yield takeEvery(GET_CAMPAIGN_FOR_ADMIN, getCampaign);
}

function* getCampaign({ payload }) {
    const { id } = payload;
    try {
        const resData = yield call(getCampaignAsync, id);
        if (!resData.errors) {
            let campaign = resData.data.getOneCampaign;
            if(campaign.error) {
                let message = campaign.error.message;
                yield put(getCampaignForAdminError(message));
            } else {
                yield put(getCampaignForAdminSuccess(campaign));
            }
        } else {
            throw new Error(resData.errors[0].message);
        }
    } catch (error) {
        yield put(getCampaignForAdminError(error.message));
    }
}

async function getCampaignAsync(id) {
    const requestBody = reqBody.getCampaignForAdminBody(id);
    const resData = await fetchRes(requestBody);
    return resData;
}

export function* watchGetBrand() {
    yield takeEvery(GET_BRAND_FOR_ADMIN, getBrand);
}

function* getBrand({ payload }) {
    const { id } = payload;
    try {
        const resData = yield call(getBrandAsync, id);
        if (!resData.errors) {
            let brand = resData.data.getOneBrand;
            if(brand.error) {
                let message = brand.error.message;
                yield put(getBrandForAdminError(message));
            } else {
                yield put(getBrandForAdminSuccess(brand));
            }
        } else {
            throw new Error(resData.errors[0].message);
        }
    } catch (error) {
        yield put(getBrandForAdminError(error.message));
    }
}

async function getBrandAsync(id) {
    const requestBody = reqBody.getBrandForAdminBody(id);
    const resData = await fetchRes(requestBody);
    return resData;
}

export function* watchGetInfluencer() {
    yield takeEvery(GET_INFLUENCER_FOR_ADMIN, getInfluencer);
}

function* getInfluencer({ payload }) {
    const { id } = payload;
    try {
        const resData = yield call(getInfluencerAsync, id);
        if (!resData.errors) {
            let influencer = resData.data.getOneInfluencer;
            if(influencer.error) {
                let message = influencer.error.message;
                yield put(getInfluencerForAdminError(message));
            } else {
                yield put(getInfluencerForAdminSuccess(influencer));
            }
        } else {
            throw new Error(resData.errors[0].message);
        }
    } catch (error) {
        yield put(getInfluencerForAdminError(error.message));
    }
}

async function getInfluencerAsync(id) {
    const requestBody = reqBody.getInfluencerForAdminBody(id);
    const resData = await fetchRes(requestBody);
    return resData;
}


export function* watchUpdateVStatus() {
    yield takeEvery(UPDATE_V_STATUS, updateVStatusFunc);
}

function*updateVStatusFunc({ payload }) {
    const { v_status,id,entity } = payload;
    try {
        const resData = yield call(updateVStatusAsync, v_status,id,entity);
        if (!resData.errors) {
            let success = resData.data.updateVStatus;
            if(success.error) {
                let message = success.error.message;
                yield put(updateVStatusError(message));
            } else {
                yield put(updateVStatusSuccess("changed"));
            }
        } else {
            throw new Error(resData.errors[0].message);
        }
    } catch (error) {
        yield put(updateVStatusError(error.message));
    }
}

async function updateVStatusAsync(v_status,id,entity) {
    const requestBody = reqBody.updateVStatusBody(v_status,id,entity);
    const resData = await fetchRes(requestBody);
    return resData;
}


export function* watchUpdateCampaignStatus() {
    yield takeEvery(UPDATE_CAMPAIGN_STATUS, updateCampaignStatusFunc);
}

function*updateCampaignStatusFunc({ payload }) {
    const { status,id } = payload;
    try {
        const resData = yield call(updateCampaignStatusAsync, status,id);
        if (!resData.errors) {
            let success = resData.data.updateCampaignStatus;
            if(success.error) {
                let message = success.error.message;
                yield put(updateCampaignStatusError(message));
            } else {
                yield put(updateCampaignStatusSuccess("changed"));
            }
        } else {
            throw new Error(resData.errors[0].message);
        }
    } catch (error) {
        yield put(updateCampaignStatusError(error.message));
    }
}

async function updateCampaignStatusAsync(status,id) {
    const requestBody = reqBody.updateCampaignStatusBody(status,id);
    const resData = await fetchRes(requestBody);
    return resData;
}


export function* watchUpdateInfluencerAssignment() {
    yield takeEvery(UPDATE_INFLUENCER_ASSIGNMENT, updateInfluencerAssignmentFunc);
}

function*updateInfluencerAssignmentFunc({ payload }) {
    const { campaign_id, influencer_id, to_assign } = payload;
    try {
        const resData = yield call(updateInfluencerAssignmentAsync, campaign_id, influencer_id, to_assign);
        if (!resData.errors) {
            let success = resData.data.updateInfluencerAssignment;
            if(success.error) {
                let message = success.error.message;
                yield put(updateCampaignStatusError(message));
            } else {
                yield put(updateCampaignStatusSuccess("changed"));
            }
        } else {
            throw new Error(resData.errors[0].message);
        }
    } catch (error) {
        yield put(updateCampaignStatusError(error.message));
    }
}

async function updateInfluencerAssignmentAsync(campaign_id, influencer_id, to_assign) {
    const requestBody = reqBody.updateInfluencerAssignmentBody(campaign_id, influencer_id, to_assign);
    const resData = await fetchRes(requestBody);
    return resData;
}

export function* watchDeleteCampaign() {
    yield takeEvery(DELETE_CAMPAIGN, deleteCampaignFunc);
}

function*deleteCampaignFunc({ payload }) {
    const { id } = payload;
    try {
        const resData = yield call(deleteCampaignAsync, id);
        if (!resData.errors) {
            let success = resData.data.deleteCampaign;
            if(success.error) {
                let message = success.error.message;
                yield put(deleteCampaignError(message));
            } else {
                yield put(deleteCampaignSuccess("deleted"));
            }
        } else {
            throw new Error(resData.errors[0].message);
        }
    } catch (error) {
        yield put(deleteCampaignError(error.message));
    }
}

async function deleteCampaignAsync(id) {
    const requestBody = reqBody.deleteCampaignBody(id);
    const resData = await fetchRes(requestBody);
    return resData;
}

export function* watchDeleteUser() {
    yield takeEvery(DELETE_USER, deleteUserFunc);
}

function*deleteUserFunc({ payload }) {
    const { id,userType } = payload;
    try {
        const resData = yield call(deleteUserAsync, id,userType);
        if (!resData.errors) {
            let success = resData.data.deleteUser;
            if(success.error) {
                let message = success.error.message;
                yield put(deleteUserError(message));
            } else {
                yield put(deleteUserSuccess("deleted"));
            }
        } else {
            throw new Error(resData.errors[0].message);
        }
    } catch (error) {
        yield put(deleteUserError(error.message));
    }
}

async function deleteUserAsync(id,userType) {
    const requestBody = reqBody.deleteUserBody(id,userType);
    const resData = await fetchRes(requestBody);
    return resData;
}

export function* watchGetCounts() {
    yield takeEvery(GET_COUNTS, getCountsFunc);
}

function*getCountsFunc({}) {
    try {
        const resData = yield call(getCountsAsync);
        if (!resData.errors) {
            let success = resData.data.getCounts;
            if(success.error) {
                let message = success.error.message;
                yield put(getCountsError(message));
            } else {
                yield put(getCountsSuccess(success));
            }
        } else {
            throw new Error(resData.errors[0].message);
        }
    } catch (error) {
        yield put(getCountsError(error.message));
    }
}

async function getCountsAsync() {
    const requestBody = reqBody.getCountsBody();
    const resData = await fetchRes(requestBody);
    return resData;
}

export function* watchGetGroupedCounts() {
    yield takeEvery(GET_GROUPED_COUNTS, getGroupedCountsFunc);
}

function*getGroupedCountsFunc({}) {
    try {
        const resData = yield call(getGroupedCountsAsync);
        if (!resData.errors) {
            let success = resData.data.getGroupedCounts;
            if(success.error) {
                let message = success.error.message;
                yield put(getGroupedCountsError(message));
            } else {
                yield put(getGroupedCountsSuccess(success.posted_cpn_per_day));
            }
        } else {
            throw new Error(resData.errors[0].message);
        }
    } catch (error) {
        yield put(getCountsError(error.message));
    }
}

async function getGroupedCountsAsync() {
    const requestBody = reqBody.getGroupedCountsBody();
    const resData = await fetchRes(requestBody);
    return resData;
}


export function* watchGetUserAnalyzer() {
    yield takeEvery(GET_ACCOUNT_ANALYZER, getUserAnalyzerFunc);
}

function* getUserAnalyzerFunc({ payload }) {
    try {
        const { id } = payload;
        const resData = yield call(getUserAnalyzerAsync,id);
        if (!resData.errors) {
            let success = resData.data.getOneInfluencer;
            if(success.error) {
                let message = success.error.message;
                yield put(getAccountAnalyzerError(message));
            } else {
                yield put(getAccountAnalyzerSuccess(success.instagram_user_details));
            }
        } else {
            throw new Error(resData.errors[0].message);
        }
    } catch (error) {
        yield put(getAccountAnalyzerError(error.message));
    }
}

async function getUserAnalyzerAsync(id) {
    console.log("here");
    const requestBody = reqBody.getAccountAnalyzerBody(id);
    const resData = await fetchRes(requestBody);
    return resData;
}


export function* watchGetPostAnalyzer() {
    yield takeEvery(GET_POST_ANALYZER, getPostAnalyzerFunc);
}

function* getPostAnalyzerFunc({ payload }) {
    try {
        const { link } = payload;
        const resData = yield call(getPostAnalyzerAsync,link);
        if (!resData.errors) {
            let success = resData.data.getPostAnalysis;
            if(success.error) {
                let message = success.error.message;
                yield put(getPostAnalyzerError(message));
            } else {
                yield put(getPostAnalyzerSuccess(success.data));
            }
        } else {
            throw new Error(resData.errors[0].message);
        }
    } catch (error) {
        yield put(getPostAnalyzerError(error.message));
    }
}

async function getPostAnalyzerAsync(link) {
    const requestBody = reqBody.getGetPostAnalysisBody(link);
    const resData = await fetchRes(requestBody);
    return resData;
}

export function* watchGetBetaUsers() {
    yield takeEvery(GET_BETA_USERS, getBetaUsersFunc);
}

function* getBetaUsersFunc({ payload }) {
    try {
        const { email_verified } = payload;
        const resData = yield call(getBetaUsersAsync,email_verified);
        if (!resData.errors) {
            let success = resData.data.getBetaUsers;
            if(success.error) {
                let message = success.error.message;
                yield put(getBetaUsersError(message));
            } else {
                yield put(getBetaUsersSuccess(success.data));
            }
        } else {
            throw new Error(resData.errors[0].message);
        }
    } catch (error) {
        yield put(getBetaUsersError(error.message));
    }
}

async function getBetaUsersAsync(email_verified) {
    const requestBody = reqBody.getGetBetaUsers(email_verified);
    const resData = await fetchRes(requestBody);
    return resData;
}

export function* watchSetBetaUser() {
    yield takeEvery(SET_BETA_USER, setBetaUserFunc);
}

function* setBetaUserFunc({ payload }) {
    try {
        const { betaUserId,v_status} = payload;
        const resData = yield call(getBetaUserAsync,betaUserId,v_status);
        if (!resData.errors) {
            let success = resData.data.updateBetaUserVStatus;
            if(success.error) {
                let message = success.error.message;
                yield put(setBetaUserError(message));
            } else {
                yield put(setBetaUserSuccess(resData.data.data));
            }
        } else {
            throw new Error(resData.errors[0].message);
        }
    } catch (error) {
        yield put(setBetaUserError(error.message));
    }
}

async function getBetaUserAsync(betaUserId,v_status) {
    const requestBody = reqBody.getUpdateBetaUserVStatus(betaUserId,v_status);
    const resData = await fetchRes(requestBody);
    return resData;
}

// ig user analysis
async function getIgUserAnalysisAsync(username) {
    const requestBody = reqBody.getIgUserAnalysis(username);
    const resData = await fetchRes(requestBody);
    return resData;
}

export function* watchGetIgUserAnalysis() {
    yield takeEvery(GET_IG_USER_ANALYSIS, getIgUserAnalysisFunc);
}

function* getIgUserAnalysisFunc({ payload }) {
    try {
        const { username } = payload;
        const resData = yield call(getIgUserAnalysisAsync,username);
        if (!resData.errors) {
            let status = resData.data.getVIGUserAnalysis;
            if(status.error || !status.hasData) {
                let message = status.error.message;
                yield put(getIgUserAnalysisError(message));
            } else {
                yield put(getIgUserAnalysisSuccess(status.data));
            }
        } else {
            throw new Error(resData.errors[0].message);
        }
    } catch (error) {
        yield put(getIgUserAnalysisError(error.message));
    }
}

// ig user analysis client
async function getIgUserAnalysisClientAsync(username) {
    console.log("getIgUserAnalysisClient called with username", username);
    const user = igUser.get(username);
    return user;
}

export function* watchGetIgUserAnalysisClient() {
    yield takeEvery(GET_IG_USER_ANALYSIS_CLIENT, getIgUserAnalysisClientFunc);
}

function* getIgUserAnalysisClientFunc({ payload }) {
    try {
        const { username } = payload;
        const user = yield call(getIgUserAnalysisClientAsync,username);
        console.log({user});
        yield put(getIgUserAnalysisClientSuccess(user));
    } catch (error) {
        yield put(getIgUserAnalysisClientError(error.message));
    }
}

export default function* rootSaga() {
    yield all([
        fork(watchGetInfluencers),
        fork(watchGetBrands),
        fork(watchGetCampaigns),
        fork(watchGetInfluencer),
        fork(watchGetBrand),
        fork(watchGetCampaign),
        fork(watchUpdateVStatus),
        fork(watchUpdateCampaignStatus),
        fork(watchUpdateInfluencerAssignment),
        fork(watchDeleteCampaign),
        fork(watchDeleteUser),
        fork(watchGetCounts),
        fork(watchGetGroupedCounts),
        fork(watchGetUserAnalyzer),
        fork(watchGetPostAnalyzer),
        fork(watchGetBetaUsers),
        fork(watchSetBetaUser),
        fork(watchGetIgUserAnalysis),
        fork(watchGetIgUserAnalysisClient)
    ]);
}
