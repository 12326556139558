import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR, LOGIN_ADMIN,
  VERIFY_OTP,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_ERROR,
  RESEND_OTP,
  RESEND_OTP_SUCCESS,
  RESEND_OTP_ERROR, FINAL_REGISTER, FINAL_REGISTER_SUCCESS, FINAL_REGISTER_ERROR
} from "../actions";

export const loginUser = (userInput, history) => ({
  type: LOGIN_USER,
  payload: { userInput, history },
});

export const loginAdmin = (userInput, history) => ({
  type: LOGIN_ADMIN,
  payload: { userInput, history },
});

export const loginUserSuccess = (userType, userId) => ({
  type: LOGIN_USER_SUCCESS,
  payload: { userType, userId },
});
export const loginUserError = (message) => ({
  type: LOGIN_USER_ERROR,
  payload: { message },
});

export const logoutUser = (history) => ({
  type: LOGOUT_USER,
  payload: { history },
});

export const registerUser = (userInput, history) => ({
  type: REGISTER_USER,
  payload: { userInput, history },
});
export const registerUserSuccess = (userType, userId) => ({
  type: REGISTER_USER_SUCCESS,
  payload: { userType, userId },
});
export const registerUserError = (message) => ({
  type: REGISTER_USER_ERROR,
  payload: { message },
});

export const forgotPassword = (forgotUserMail, history) => ({
  type: FORGOT_PASSWORD,
  payload: { forgotUserMail, history },
});
export const forgotPasswordSuccess = (forgotUserMail) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: forgotUserMail,
});
export const forgotPasswordError = (message) => ({
  type: FORGOT_PASSWORD_ERROR,
  payload: { message },
});

export const resetPassword = ({ resetPasswordCode, newPassword, history }) => ({
  type: RESET_PASSWORD,
  payload: { resetPasswordCode, newPassword, history },
});
export const resetPasswordSuccess = (newPassword) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: newPassword,
});
export const resetPasswordError = (message) => ({
  type: RESET_PASSWORD_ERROR,
  payload: { message },
});

export const verifyOtp = (otp, history) => ({
  type: VERIFY_OTP,
  payload:  {otp, history},
});

export const verifyOtpSuccess = (message) => ({
  type: VERIFY_OTP_SUCCESS,
  payload: message ,
});

export const verifyOtpError = (message) => ({
  type: VERIFY_OTP_ERROR,
  payload: { message },
});

export const resendOtp = () => ({
  type: RESEND_OTP
});

export const resendOtpSuccess = (message) => ({
  type: RESEND_OTP_SUCCESS,
  payload: message ,
});

export const resendOtpError = (message) => ({
  type: RESEND_OTP_ERROR,
  payload: { message },
});

export const finalRegister = (history, betaUserId, password) => ({
  type: FINAL_REGISTER,
  payload:  {history, betaUserId, password},
});

export const finalRegisterSuccess = (message) => ({
  type: FINAL_REGISTER_SUCCESS,
  payload: message ,
});

export const finalRegisterError = (message) => ({
  type: FINAL_REGISTER_ERROR,
  payload: { message },
});