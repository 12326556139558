import { all } from 'redux-saga/effects';
import authSagas from "./auth/saga";
import surveyListSagas from './surveyList/saga';
import surveyDetailSagas from './surveyDetail/saga';
import brandSagas from "./brand/saga";
import userSagas from "./user/saga";
import influencerSagas from "./Influencer/saga";
import adminSagas from "./admin/saga";

export default function* rootSaga(getState) {
  yield all([
    surveyListSagas(),
    surveyDetailSagas(),
    authSagas(),
    brandSagas(),
    userSagas(),
    influencerSagas(),
    adminSagas()
  ]);
}
