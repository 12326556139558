export const getLoginBody = (email, password, userType) => ({
  query: `
      query Login ($email: String!, $password: String!, $userType: String!) {
        login (email: $email, password: $password, userType: $userType) {
        token
        userId
       
        error {
          message
        }
      }
    }
		`,
  variables: {
    email,
    password,
    userType,
  },
});

export const getLoginAdminBody = (username, password) => ({
  query: `
      query Login ($username: String!, $password: String!) {
        loginAdmin (username: $username, password: $password) {
        token
        userId
        error {
          message
        }
      }
    }
		`,
  variables: {
    username,
    password
  },
});


export const getRegisterBody = (fullname, email, phone, user_type) => ({
  query: `
      mutation BeginRegistration ($fullname: String!, $email: String!, $phone: String!, $user_type: String!) {
        beginRegistration (fullname:$fullname, email:$email, phone:$phone, user_type:$user_type) {
          status
          statusCode
          description
          data
          error {
            message
          }
        }
      }
		`,
  variables: {
    fullname,
    email,
    phone,
    user_type,
  },
});

export const getUpdateBrandBody = (updateUserInput) => ({
  query: `
    mutation UpdateBrand (
      $fullname: String,
      $email: String,
      $website: String,
      $phone: String,
      $bank_name: String,
      $bank_branch: String,
      $bank_ac_num: String,
      $ifsc: String,
      $swift_code: String,
      $pan: String
    ) {
      updateBrand(updateBrandInput: {
        fullname:	$fullname,
        email: $email,
        website: $website,
        phone: $phone,
        bank_name: $bank_name,
        bank_branch: $bank_branch,
        bank_ac_num: $bank_ac_num,
        ifsc: $ifsc,
        swift_code: $swift_code,
        pan: $pan
      }) {
        fullname
        email
        website 
        phone
        bank_name
        bank_branch
        bank_ac_num
        ifsc
        v_status
        swift_code
        pan
        error {
          message
        }
      }
    }
  `,
  variables: updateUserInput,
});

export const getUpdateInfluencerBody = (updateUserInput) => ({
  query: `
    mutation UpdateBrand (
      $fullname: String,
      $gender: String,
      $instagram_username: String,
      $facebook_url: String,
      $tiktok_username: String,
      $youtube_channel_name: String,
      $youtube_owner_email: String,
      $aadhar_card_num: String,
      $add_line1: String,
      $add_line2: String,
      $city: String,
      $state: String,
      $ig_c_p_story_post: Int,
      $ig_c_p_video_post: Int,
      $ig_c_p_image_post: Int,
      $pincode: Int,
	    $country: String,
	    $date_of_birth: String,
      $bank_name: String,
      $bank_branch: String,
      $bank_ac_num: String,
      $ifsc: String,
      $swift_code: String,
      $pan: String
    ) {
      updateInfluencer(updateInfluencerInput: {
        fullname:	$fullname,
        gender: $gender,
        ig_c_p_story_post: $ig_c_p_story_post,
        ig_c_p_video_post: $ig_c_p_video_post,
        ig_c_p_image_post: $ig_c_p_image_post,
        instagram_username: $instagram_username,
        facebook_url: $facebook_url,
        tiktok_username: $tiktok_username,
        youtube_channel_name: $youtube_channel_name,
        youtube_owner_email: $youtube_owner_email,
        aadhar_card_num: $aadhar_card_num,
        add_line1: $add_line1,
        add_line2: $add_line2,
        city: $city,
        state: $state,
        pincode: $pincode,
        country: $country,
        date_of_birth: $date_of_birth,
        bank_name: $bank_name,
        bank_branch: $bank_branch,
        bank_ac_num: $bank_ac_num,
        ifsc: $ifsc,
        swift_code: $swift_code,
        pan: $pan
      }) {
        fullname
        email
        phone
        gender
        instagram_username
        facebook_url
        tiktok_username
        youtube_channel_name
        youtube_owner_email
        aadhar_card_num
        add_line1
        add_line2
        city
        state
        pincode
        country
        date_of_birth
        bank_name
        bank_branch
        bank_ac_num
        ifsc
        swift_code
        pan
        error {
          message
        }
      }
    }
  `,
  variables: updateUserInput,
});

export const getBrandBody = (id) => ({
  query: `
    query GetBrand($id:ID){
      getOneBrand(id: $id) {
          id
          fullname
          website
          phone
          email
          password
          sign_up_date
          bank_name
          bank_branch
          bank_ac_num
          ifsc
          swift_code
          pan
          v_status
          error {
            message
          }
        }
    }
  `,
  variables:{id}
});

export const getInfluencerBody = (id) => ({
  query: `
    query GetInfluencer($id: ID){
      getOneInfluencer(id: $id) {
        id
        fullname
        gender
        phone
        email
        password
        sign_up_date
        instagram_username
        facebook_url
        tiktok_username
        youtube_channel_name
        youtube_owner_email
        aadhar_card_num
        add_line1
        add_line2
        city
        state
        pincode
        country
        date_of_birth
        bank_name
        bank_branch
        bank_ac_num
        ifsc
        swift_code
        pan
        v_status
        error {
          message
        }
      }
    }
  `,
  variables:{id}
});

export const getCreateCampaignBody = (createCampaignInput,status) =>{
  createCampaignInput.status = status?status:"posted";
  return({
    query: `
    mutation CreateCampaign ($createCampaignInput:CampaignInput!) {
      createCampaign (campaignInput: $createCampaignInput) {
        id
        title
        last_date_to_apply
        post_date
        description
        influencers_to_be_invited
        amount_payable
        private
        status
        error {
          message
        }      
      } 
    }
  `,
    variables: {createCampaignInput},
  })};


export const getUpdateCampaignBody = (createCampaignInput,status) => {
  createCampaignInput.status = status?status:"posted";
  return({
  query: `
    mutation UpdateCampaign ($createCampaignInput:CampaignInput!) {
      updateCampaign (campaignInput: $createCampaignInput) {
        id
        title
        last_date_to_apply
        post_date
        description
        influencers_to_be_invited
        amount_payable
        private
        status
        error {
          message
        }      
      } 
    }
  `,
  variables: {createCampaignInput},
})};

export const getGetCampaignsOfBrandBody = (type,id) => ({
  query: `
      query GetCampaignsOfBrand($id: ID!) {
        getOneBrand(id: $id) {
          ${type} {
            id
            title
            post_date
            status
            v_status
            amount_payable
            last_date_to_apply
            influencer_state
            influencer_country
            description
            influencers_to_be_invited
            audience_size
            audience_state
            audience_country
            category
            private
            invited_influencers{
              influencer{
                id
                gender
                fullname
                country
                state
                invitation {
                  id
              date
              influencer_id
              campaign_id
              offered_rate
              requested_rate
              influencer_res_to_offer
              requests_left
              brand_res_to_req
              turn
              expired
                }
              }
            }
            applied_influencers{
              influencer{
                id
                gender
                fullname
                country
                state
              }
            }
            completed_influencers{
              influencer{
                id
                fullname
                gender
                country
                state
              }
            }
            active_influencers{
              influencer{
                id
                fullname
                gender
                country
                state
              }
              links {
                link
                date
              }
            }       
          }
          error {
            message
          }
        }
      }

  `,
  variables: {
    id
  },
});

export const getGetInfluencersBody = (filter, mode="forCampaign") => ({
  query: `
    query GetInfluencers($filter: InfluencerFilter!,$mode: String){
  getInfluencers(filter:$filter, mode:$mode){
    list{
      id
      fullname
      country
      state
      instagram_username
      instagram_user_details
      facebook_url
      tiktok_username
      youtube_channel_name
    }
    error {
      message
    }
  }
}
  `,
  variables: {filter, mode},
});

export const getCreateInvitationBody = (influencerId, campaignId) => ({
  query: `
    mutation CreateInvitation ($influencerId: ID!, $campaignId: ID!) {
      createInvitation (influencer_id: $influencerId, campaign_id: $campaignId) {
        id
      }
    }
	`,
  variables: {
    influencerId,
    campaignId,
  },
});

export const getGetCampaignsForInfluencerBody = (type, id) => ({
  query: `
    query GetCampaignsForInfluencer ($id: ID!) {
      getOneInfluencer (id: $id){
        ${type} {
          links {
                link
                date
              }
          influencer_fee
          campaign{
            id
            title
            post_date
            amount_payable
            last_date_to_apply
            description
            status
            isAssigned
            invitation {
              id
              date
              influencer_id
              campaign_id
              offered_rate
              requested_rate
              influencer_res_to_offer
              requests_left
              brand_res_to_req
              turn
              expired
            }
          }
        }
        error {
          message
        }
      }
    }
  `,
  variables: { id },
});

export const getCreateBidBody = (
  campaign_id,
  influencer_fee
) => ({
  query: `
    mutation CreateBid ( $campaign_id: ID!, $influencer_fee: Int) {
      createBid (campaign_id: $campaign_id, influencer_fee: $influencer_fee) {
       success
       error {
        message
       }
      }
    }
	`,
  variables: {
    campaign_id,
    influencer_fee: influencer_fee ? +influencer_fee : null,
  },
});

export const getAddLinkBody = (campaign_id, link) => ({
  query: `
    mutation AddLink ($campaign_id: ID!, $link: String!) {
      addLink (campaign_id: $campaign_id, link: $link) {
        success
        error {
          message
        }
      }
    }
	`,
  variables: {
    campaign_id,
    link,
  },
});

export const getGetCampaignBody = (id) => ({
  query: `
    query GetCampaign ($id: ID!) {
      getOneCampaign (id: $id) {
        id
        post_date
    title
    amount_payable
    status
    last_date_to_apply
    influencer_state
    influencer_country
    description
    audience_size
    audience_state
    audience_country
    category
    private
    invited_influencers{
      influencer{
        id
        fullname
      }
    }
    applied_influencers{
    influencer_fee
      influencer{
        id
        fullname
      }
    }
    completed_influencers{
      links {
                link
                date
              }
      influencer{
        id
        fullname
      }
    }
    active_influencers{
      links {
                link
                date
              }
      influencer{
        id
        fullname
      }
    }
    
    error{
      message
    }
      }
    }
  `,
  variables: {
    id,
  },
});

export const getInfluencersForCampaignBody = (partialName) => ({
  query: `
    query GetInfluencersForCampaign ($partialName: String!) {
      getInfluencersForInvitation (partialName:$partialName) {
        list {
          fullname
          id
        }
      }
    }
  `,
  variables: {
    partialName,
  },
});

export const getBrandsForAdminBody = (filter) => ({
  query: `query GetBrands ($filter: BrandFilter){
  getBrands(filter:$filter){
    list{
      id
      fullname
      website
      email
      v_status
    }
    error {
      message
    }
  }
  }
  `,
  variables: {
    filter
  }
});

export const getInfluencersForAdminBody = (filter, mode="forCampaign") => ({
  query:`query GetInfluencers($filter: InfluencerFilter!, $mode: String){
  getInfluencers(filter:$filter, mode:$mode){
    list{
      id
      v_status
      gender
      instagram_username
      instagram_user_details
      fullname
      country
      state
    }
    error {
      message
    }
  }
}`,
      variables: {
    filter,
        mode
  }
});

export const getCampaignsForAdminBody = (filter) => ({
  query: `query GetCampaign($filter: CampaignFilter){
  getCampaigns(filter:$filter) {
    list{
      id
      title
      post_date
      amount_payable
      last_date_to_apply
      description
      status
      v_status
    }
    error {
      message
    }
  }
  }`,
  variables:{
    filter
  }
});

export const getCampaignForAdminBody = (id) => ({
  query: `
    query GetCampaign ($id: ID!) {
      getOneCampaign (id: $id) {
        id
        post_date
    title
    amount_payable
    status
    v_status
    last_date_to_apply
    influencer_state
    influencer_country
    description
    audience_size
    audience_state
    audience_country
    category
    private
    invited_influencers{
      influencer{
        id
        isAssigned
        fullname
        country
        state
        gender
        v_status
        invitation {
                  id
              date
              influencer_id
              campaign_id
              offered_rate
              requested_rate
              influencer_res_to_offer
              requests_left
              brand_res_to_req
              turn
              expired
                }
      }
    }
    applied_influencers{
    influencer_fee
      influencer{
        id
        isAssigned
        fullname
        country
        state
        gender
        v_status
      }
    }
    completed_influencers{
      influencer{
        id
        fullname
        country
        state
        gender
        v_status
      }
    }
    active_influencers{
    links {
                link
                date
              }  
      influencer{
        id
        fullname
        country
        state
        gender
        v_status
      }
    }
    active_influencers{
    links {
                link
                date
              }  
      influencer{
        id
        fullname
        country
        state
        gender
        v_status
      }
    }
    error{
      message
    }
      }
    }
  `,
  variables: {
    id,
  },
});

export const getBrandForAdminBody = (id) => {
  id=Number(id);
  return({
  query: `
    query GetBrand($id:ID){
      getOneBrand(id: $id) {
          id
          fullname
          website
          phone
          email
          password
          sign_up_date
          bank_name
          bank_branch
          bank_ac_num
          ifsc
          swift_code
          pan
          v_status
          posted_campaigns {
            id
            title
            amount_payable
            post_date
            status
            last_date_to_apply
            description
          }
          draft_campaigns {
            id
            title
            amount_payable
            post_date
            status
            last_date_to_apply
            description
          }
          active_campaigns {
            id
            title
            amount_payable
            post_date
            status
            last_date_to_apply
            description
          }
          completed_campaigns {
            id
            title
            amount_payable
            post_date
            status
            last_date_to_apply
            description
          }
          error {
            message
          }
        }
    }
  `,
  variables: {
    id,
  },
})};

export const getInfluencerForAdminBody = (id) => ({
  query: `
    query GetInfluencer($id:ID){
      getOneInfluencer(id: $id) {
          id
        fullname
        gender
        phone
        email
        password
        sign_up_date
        instagram_username
        facebook_url
        tiktok_username
        youtube_channel_name
        youtube_owner_email
        aadhar_card_num
        add_line1
        add_line2
        city
        state
        pincode
        country
        date_of_birth
        bank_name
        bank_branch
        bank_ac_num
        ifsc
        swift_code
        pan
        v_status
          applied_campaigns {
            campaign {id
            title
            amount_payable
            post_date
            status
            isAssigned
            last_date_to_apply
            description
            }
            _date
            links {
                link
                date
              }
            influencer_fee
            error {
              message
            }
          }
          invited_campaigns {
            campaign {id
            title
            amount_payable
            post_date
            status
            last_date_to_apply
            description}
            _date
            influencer_fee
            error {
              message
            } 
          }
          active_campaigns {
            campaign {id
            title
            post_date
            amount_payable
            status
            last_date_to_apply
            description}
            _date
            links {
                link
                date
              }
            influencer_fee
            error {
              message
            }
          }
          completed_campaigns {
            campaign {id
            title
            post_date
            amount_payable
            status
            last_date_to_apply
            description}
            _date
            influencer_fee
            error {
              message
            }
          }
          error {
            message
          }
        }
    }
  `,
  variables: {
    id,
  },
});

export const updateVStatusBody = (v_status,id,entity) => ({
  query: `
    mutation UpdateVStatus (
      $v_status: String!
      $id: ID!
      $entity: String!
    ) {
      updateVStatus(v_status:$v_status,id:$id,entity:$entity) {
        success
        error {
          message
        }
      }
    }
  `,
  variables: {
    v_status,id,entity
  },
});


export const updateCampaignStatusBody = (status,id) => ({
  query: `
    mutation UpdateCampaignStatus (
      $status: String!
      $id: ID!
    ) {
      updateCampaignStatus(status:$status,id:$id) {
        success
        error {
          message
        }
      }
    }
  `,
  variables: {
    status,id
  },
});


export const updateInfluencerAssignmentBody = (campaign_id,influencer_id,toAssign) => ({
  query: `
    mutation UpdateInfluencerAssignment (
      $campaign_id: ID!
      $influencer_id: ID!
      $toAssign: Boolean!
    ) {
      updateInfluencerAssignment(campaign_id: $campaign_id, influencer_id: $influencer_id, toAssign: $toAssign) {
        success
        error {
          message
        }
      }
    }
  `,
  variables: {
    campaign_id,influencer_id,toAssign
  },
});

export const deleteCampaignBody = (id) => ({
  query: `
    mutation DeleteCampaign (
      $id: ID!
    ) {
      deleteCampaign(id:$id) {
        success
        error {
          message
        }
      }
    }
  `,
  variables: {
    id
  },
});

export const deleteUserBody = (id,userType) => ({
  query: `
    mutation DeleteCampaign (
      $id: ID!
      $userType: String!
    ) {
     deleteUser(id:$id, userType:$userType) {
        success
        error {
          message
        }
      }
    }
  `,
  variables: {
    id,
    userType
  },
});

export const getCountsBody = () =>{

  let countTopics=['total_campaigns',
    'active_campaigns',
    'completed_campaigns',
    'total_influencers',
    'total_brands'];

  return ({
  query: `
     {
     getCounts (countTopics:${JSON.stringify(countTopics)}) {
        total_campaigns
        active_campaigns
        completed_campaigns
        total_influencers
        total_brands
        error {
          message
        }
     }
     }
  `
})};

export const getGroupedCountsBody = () =>{

  let countTopics=['posted_cpn_per_day'];

  return ({
    query: `
     {
     getGroupedCounts (countTopics:${JSON.stringify(countTopics)}) {
        posted_cpn_per_day
        error {
          message
        }
     }
     }
  `
  })};

export const getInfluencerNamesByIdsBody = (ids) => {
  return({
    query:`
      query GetInfluencerNamesByIds ($ids:[ID]) {
        getInfluencerNamesByIds(ids:$ids) {
          list {
            id
            fullname
          }
          error {
            message
          }
        }
      }
    `,
    variables: {
      ids
    },
  })
};

export const userEmailVerificationBody = (otp,user_id) => {
  return({
    query:`
      mutation UserEmailVerification (
      $otp: Int!
      $user_id: ID!
    )  {
        verifyEmail(otp:$otp,betaUserId:$user_id) {
          status
          statusCode
          description
          data
          error {
            message
          }
        }
      }
    `,
    variables: {
      otp,
      user_id
    },
  })
};

export const resendOtpBody = (user_id) => {
  return({
    query:`
      mutation ResendOtp (
      $user_id: ID!
    )  {
        resendOtp(betaUserId:$user_id) {
          status
          statusCode
          description
          data
          error {
            message
          }
        }
      }
    `,
    variables: {
      user_id
    },
  })
};

export const getCompareInfluencersBody = (influencer_ids) => {
  return({
    query:`
      query CompareInfluencers ($influencer_ids:[ID!]) {
        compareInfluencers (influencer_ids:$influencer_ids) {
            instagram {
              instagram_username
              cpe
              cpm
              average_comments
              average_likes
              top_age_range
              gender_split {
                male
                female
                error {
                  message
                }
              }
              gender_split_percent {
                  malePercent
                  femalePercent
                  error {
                    message
                  }
              }
              age_split
              error {
                message
              }
            }
          }
      }
    `,
    variables: {
      influencer_ids
    },
  })
};

export const getGetPostAnalysisBody = (link) => {
  return({
    query:`
      query GetPostAnalysis ($link:String!) {
        getPostAnalysis (link:$link) {
          data
          error {
            message
          }
        }
      }
    `,
    variables: {
      link
    },
  })
};

export const getCheckDraftBody = (influencer_ids) => {
  return({
    query:`
      query CheckDraft ($influencer_ids:[ID!]) {
        checkDraft (influencer_ids:$influencer_ids) {
        status
          statusCode
          description
          data
          error {
            message
          }
        }
      }
    `,
    variables: {
      influencer_ids
    },
  })
};

export const finalRegistration = (betaUserId, password) => {
  return({
    query:`
      mutation FinalRegistration ($betaUserId: ID!, $password: String!) {
        register (betaUserId: $betaUserId, password: $password) {
          token
          userId
       
        error {
          message
        }
        }
      }
    `,
    variables: {
      betaUserId, password
    },
  })
};


export const getAccountAnalyzerBody = (id,mode="userReport") => {
  return({
    query:`
      query GetInstagramAccountAnalyzer ($id: ID, $mode:String) {
        getOneInfluencer (id:$id, mode:$mode) {
          instagram_user_details
        }
      }
    `,
    variables: {
      id,
      mode
    },
  })
};

export const getRespondToOfferedRate = (invitation_id,response,requested_rate) => {
  return({
    query:`
      mutation RespondToOfferedRate ($invitation_id: ID!, $response: String!, $requested_rate: Int) {
        respondToOfferedRate (invitation_id:$invitation_id, response:$response, requested_rate: $requested_rate) {
            data
            success
            error {
              message
            }
        }
      }
    `,
    variables: {
      invitation_id,response,requested_rate
    },
  })
};


export const getRespondToRequestedRate = (invitation_id,response,offered_rate) => {
  return({
    query:`
      mutation RespondToRequestedRate ($invitation_id: ID!, $response: String!, $offered_rate: Int) {
        respondToRequestedRate (invitation_id:$invitation_id, response:$response, offered_rate: $offered_rate) {
            data
            success
            error {
              message
            }
        }
      }
    `,
    variables: {
      invitation_id,response,offered_rate
    },
  })
};

export const getGetBetaUsers = (email_verified) => {
  return({
    query:`
      query GetBetaUsers ($email_verified: Boolean!) {
        getBetaUsers (email_verified:$email_verified) {
            data
           
            error {
              message
            }
        }
      }
    `,
    variables: {
      email_verified
    },
  })
};

export const getUpdateBetaUserVStatus = (betaUserId,v_status) => {
  return({
    query:`
      mutation UpdateBetaUserVStatus ($betaUserId: ID!, $v_status: String!) {
        updateBetaUserVStatus (betaUserId: $betaUserId, v_status: $v_status) {
            data
            success
            error {
              message
            }
        }
      }
    `,
    variables: {
      betaUserId,v_status
    },
  })
};

export const getSaveAccessToken  = (token) => {
  return({
    query:`
      mutation SaveAccessToken ($token: String!) {
        saveAccessToken (token:$token) {
            data
            success
            error {
              message
            }
        }
      }
    `,
    variables: {
      token
    },
  })
};

export const getIgUserAnalysis = (username) => {
  return({
    query:`
      query GetVIGUserAnalysis($identifier: String!) {
        getVIGUserAnalysis(identifier: $identifier) {
          status
          hasData
          data
          error {
            message
          }
        }
      }
      `,
    variables: {
      identifier: username
    },
  })
};