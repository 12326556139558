const post = async (url, requestBody) => {
  const res = await fetch(url, {
    method: "POST",
    body: JSON.stringify(requestBody),
    headers: {
      "Content-Type": "application/json"
    },
  });

  return res;
};

const get = async (url) => {
  const res = await fetch(url, {
    method: "GET"
  })
  return res;
}

export {post, get}