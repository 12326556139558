import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  GET_CAMPAIGNS_FOR_INFLUENCER,
  CREATE_BID,
  ADD_LINK,
  getCampaignsOfBrandSuccess,
  getCampaignsOfBrandError,
  RESPOND_TO_REQUESTED_RATE,
  saveAccessTokenError,
  saveAccessTokenSuccess, SAVE_ACCESS_TOKEN
} from "../actions";

import {
  getCampaignsForInfluencerSuccess,
  getCampaignsForInfluencerError,
  respondToRequestedRateSuccess,
  createBidSuccess,
  createBidError,
  addLinkSuccess,
  addLinkError, respondToRequestedRateError,
} from "./actions";

import { capitalizeStr, fetchRes, removeEmptyProperties } from "../helpers";
import * as reqBody from "../requestBody";

export function* watchGetCampaignsForInfluencer() {
  console.log("caught get campaigns for influencer saga");
  yield takeEvery(GET_CAMPAIGNS_FOR_INFLUENCER, getCampaignsForInfluencerFunc);
}

export function* watchCreateBid() {
  console.log("caught create bid saga");
  yield takeEvery(CREATE_BID, createBidFunc);
}

export function* watchAddLink() {
  yield takeEvery(ADD_LINK, addLinkFunc);
}

export function* watchRespondReq() {
  yield takeEvery(RESPOND_TO_REQUESTED_RATE, respondReqFunc);
}

function* getCampaignsForInfluencerFunc({ payload }) {
  console.log("get campaigns for influencer func");
  const { type } = payload;
  try {
    const resData = yield call(getCampaignsForInfluencerAsync, type);
    if(resData.data) {
      if(!resData.data.getOneInfluencer.error) {
        const campaigns = resData.data.getOneInfluencer[type+"_campaigns"];
        yield put(getCampaignsForInfluencerSuccess(type, campaigns));
      } else {
        yield put(getCampaignsForInfluencerError(resData.data.getOneInfluencer.error.message));
      }
    } else {
      throw new Error("Could not get campaigns!");
    }
  } catch (error) {
    yield put(getCampaignsForInfluencerError(error.message));
  }
}

async function getCampaignsForInfluencerAsync(type) {
  const requestBody = reqBody.getGetCampaignsForInfluencerBody(type+"_campaigns", localStorage.getItem("userId"));
  const resData = await fetchRes(requestBody);
  return resData;
}

function* createBidFunc({ payload }) {
  console.log("create bid saga");
  const { campaign_id, influencer_fee } = payload;
  try {
    const resData = yield call(
      createBidAsync,
      campaign_id,
      influencer_fee
    );
    if (!resData.errors) {
      const bid = resData.data.createBid;
      if(bid.error) {
        yield put(createBidError(bid.error.message));
      } else
        yield put(createBidSuccess(bid));
    } else {
      throw new Error("Could not apply to campaign!");
    }
  } catch (error) {
    yield put(createBidError(error.message));
  }
}

async function createBidAsync(campaign_id, influencer_fee) {
  const requestBody = reqBody.getCreateBidBody(
    campaign_id,
    influencer_fee
  );
  const resData = await fetchRes(requestBody);
  return resData;
}

function* addLinkFunc({ payload }) {
  console.log("add link saga");
  const { campaign_id, link } = payload;
  try {
    const resData = yield call(addLinkAsync, campaign_id, link);
    if (!resData.errors) {
      const links = resData.data.addLink;
      if(links.error) {
        yield put(addLinkError(links.error.message));
      } else
        yield put(addLinkSuccess(campaign_id, link));
    } else {
      throw new Error("Could not add link!");
    }
  } catch (error) {
    yield put(addLinkError(error.message));
  }
}

async function addLinkAsync(campaign_id, link) {
  const requestBody = reqBody.getAddLinkBody(campaign_id, link);
  const resData = await fetchRes(requestBody);
  return resData;
}


function* respondReqFunc({ payload }) {
  const { invitation_id, response, requested_rate } = payload;
  try {
    const resData = yield call(respondReqAsync, invitation_id, response, requested_rate);
    if (!resData.errors) {
      const links = resData.data.respondToOfferedRate;
      if(links.error) {
        yield put(respondToRequestedRateError(links.error.message));
      } else {
        console.log("called");
        yield put(respondToRequestedRateSuccess("added"));
      }
    } else {
      throw new Error("Could not add link!");
    }
  } catch (error) {
    yield put(respondToRequestedRateError(error.message));
  }
}

async function respondReqAsync(invitation_id, response, requested_rate) {
  const requestBody = reqBody.getRespondToOfferedRate(invitation_id, response, requested_rate);
  const resData = await fetchRes(requestBody);
  return resData;
}

export function* watchSaveAccessToken() {
  yield takeEvery(SAVE_ACCESS_TOKEN, saveAccessTokenFunc);
}

function* saveAccessTokenFunc({ payload }) {
  const { access_token } = payload;
  try {
    const resData = yield call(saveAccessTokenAsync, access_token);
    if (!resData.errors) {
      const links = resData.data.saveAccessToken;
      if(links.error) {
        yield put(saveAccessTokenError(links.error.message));
      } else
        yield put(saveAccessTokenSuccess());
    } else {
      throw new Error("Could not add link!");
    }
  } catch (error) {
    yield put(saveAccessTokenError(error.message));
  }
}

async function saveAccessTokenAsync(access_token) {
  const requestBody = reqBody.getSaveAccessToken(access_token);
  const resData = await fetchRes(requestBody);
  return resData;
}

export default function* rootSaga() {
  yield all([
    fork(watchGetCampaignsForInfluencer),
    fork(watchCreateBid),
    fork(watchAddLink),
    fork(watchRespondReq),
      fork( watchSaveAccessToken)
  ]);
}
