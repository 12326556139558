import {
    GET_CAMPAIGNS_FOR_ADMIN,
    GET_BRANDS_FOR_ADMIN,
    GET_INFLUENCERS_FOR_ADMIN,
    GET_CAMPAIGNS_FOR_ADMIN_SUCCESS,
    GET_BRANDS_FOR_ADMIN_SUCCESS,
    GET_INFLUENCERS_FOR_ADMIN_SUCCESS,
    GET_CAMPAIGNS_FOR_ADMIN_ERROR,
    GET_BRANDS_FOR_ADMIN_ERROR,
    GET_INFLUENCERS_FOR_ADMIN_ERROR,
    GET_CAMPAIGN_FOR_ADMIN,
    GET_CAMPAIGN_FOR_ADMIN_ERROR,
    GET_BRAND_FOR_ADMIN_ERROR,
    GET_INFLUENCER_FOR_ADMIN_ERROR,
    GET_INFLUENCER_FOR_ADMIN_SUCCESS,
    GET_BRAND_FOR_ADMIN_SUCCESS,
    GET_CAMPAIGN_FOR_ADMIN_SUCCESS,
    GET_INFLUENCER_FOR_ADMIN,
    GET_BRAND_FOR_ADMIN,
    UPDATE_V_STATUS,
    UPDATE_V_STATUS_SUCCESS,
    UPDATE_V_STATUS_ERROR,
    UPDATE_CAMPAIGN_STATUS,
    UPDATE_CAMPAIGN_STATUS_SUCCESS,
    UPDATE_CAMPAIGN_STATUS_ERROR,
    UPDATE_INFLUENCER_ASSIGNMENT,
    UPDATE_INFLUENCER_ASSIGNMENT_SUCCESS,
    UPDATE_INFLUENCER_ASSIGNMENT_ERROR,
    DELETE_CAMPAIGN,
    DELETE_CAMPAIGN_ERROR,
    DELETE_CAMPAIGN_SUCCESS,
    DELETE_USER,
    DELETE_USER_SUCCESS,
    DELETE_USER_ERROR,
    GET_COUNTS,
    GET_COUNTS_SUCCESS,
    GET_GROUPED_COUNTS,
    GET_COUNTS_ERROR,
    GET_GROUPED_COUNTS_SUCCESS,
    GET_GROUPED_COUNTS_ERROR,
    GET_ACCOUNT_ANALYZER,
    GET_ACCOUNT_ANALYZER_SUCCESS,
    GET_ACCOUNT_ANALYZER_ERROR,
    GET_POST_ANALYZER,
    GET_POST_ANALYZER_SUCCESS,
    GET_POST_ANALYZER_ERROR,
    GET_BETA_USERS,
    GET_BETA_USERS_SUCCESS,
    GET_BETA_USERS_ERROR,
    SET_BETA_USER,
    SET_BETA_USER_SUCCESS, SET_BETA_USER_ERROR,
    GET_IG_USER_ANALYSIS, GET_IG_USER_ANALYSIS_SUCCESS, GET_IG_USER_ANALYSIS_ERROR,
    GET_IG_USER_ANALYSIS_CLIENT, GET_IG_USER_ANALYSIS_CLIENT_SUCCESS, GET_IG_USER_ANALYSIS_CLIENT_ERROR
} from "../actions";

export const getCampaignsForAdmin = (filter) => ({
    type: GET_CAMPAIGNS_FOR_ADMIN,
    payload: {filter}
});

export const getBrandsForAdmin = (filter) => ({
    type: GET_BRANDS_FOR_ADMIN,
    payload: {filter}
});

export const getInfluencersForAdmin = (filter) => ({
    type: GET_INFLUENCERS_FOR_ADMIN,
    payload: {filter}
});

export const getCampaignsForAdminSuccess = (campaigns) => ({
    type: GET_CAMPAIGNS_FOR_ADMIN_SUCCESS,
    payload: {campaigns}
});

export const getBrandsForAdminSuccess = (brands) => ({
    type: GET_BRANDS_FOR_ADMIN_SUCCESS,
    payload: {brands}
});

export const getInfluencersForAdminSuccess = (influencers) => ({
    type: GET_INFLUENCERS_FOR_ADMIN_SUCCESS,
    payload: {influencers}
});

export const getCampaignsForAdminError = (message) => ({
    type: GET_CAMPAIGNS_FOR_ADMIN_ERROR,
    payload: {message}
});

export const getBrandsForAdminError = (message) => ({
    type: GET_BRANDS_FOR_ADMIN_ERROR,
    payload: {message}
});

export const getInfluencersForAdminError = (message) => ({
    type: GET_INFLUENCERS_FOR_ADMIN_ERROR,
    payload: {message}
});

export const getCampaignForAdmin = (id) => ({
    type: GET_CAMPAIGN_FOR_ADMIN,
    payload: {id}
});

export const getBrandForAdmin = (id) => ({
    type: GET_BRAND_FOR_ADMIN,
    payload: {id}
});

export const getInfluencerForAdmin = (id) => ({
    type: GET_INFLUENCER_FOR_ADMIN,
    payload: {id}
});

export const getCampaignForAdminSuccess = (campaign) => ({
    type: GET_CAMPAIGN_FOR_ADMIN_SUCCESS,
    payload: {campaign}
});

export const getBrandForAdminSuccess = (brand) => ({
    type: GET_BRAND_FOR_ADMIN_SUCCESS,
    payload: {brand}
});

export const getInfluencerForAdminSuccess = (influencer) => ({
    type: GET_INFLUENCER_FOR_ADMIN_SUCCESS,
    payload: {influencer}
});

export const getCampaignForAdminError = (message) => ({
    type: GET_CAMPAIGN_FOR_ADMIN_ERROR,
    payload: {message}
});

export const getBrandForAdminError = (message) => ({
    type: GET_BRAND_FOR_ADMIN_ERROR,
    payload: {message}
});

export const getInfluencerForAdminError = (message) => ({
    type: GET_INFLUENCER_FOR_ADMIN_ERROR,
    payload: {message}
});

export const updateVStatus = (v_status,id,entity) => ({
    type: UPDATE_V_STATUS,
    payload: {v_status,id,entity}
});

export const updateVStatusSuccess = (status) => ({
    type: UPDATE_V_STATUS_SUCCESS,
    status: status
});

export const updateVStatusError = (message) => ({
   type: UPDATE_V_STATUS_ERROR,
   payload: {message}
});

export const updateCampaignStatus = (status,id) => ({
    type: UPDATE_CAMPAIGN_STATUS,
    payload: {status,id}
});

export const updateCampaignStatusSuccess = (status) => ({
    type: UPDATE_CAMPAIGN_STATUS_SUCCESS,
    status: status
});

export const updateCampaignStatusError = (message) => ({
    type: UPDATE_CAMPAIGN_STATUS_ERROR,
    payload: {message}
});

export const updateInfluencerAssignment = (campaign_id, influencer_id, to_assign) => ({
    type: UPDATE_INFLUENCER_ASSIGNMENT,
    payload: { campaign_id, influencer_id, to_assign },
});
export const updateInfluencerAssignmentSuccess = (status) => ({
    type: UPDATE_INFLUENCER_ASSIGNMENT_SUCCESS,
    payload: { status },
});
export const updateInfluencerAssignmentError = (message) => ({
    type: UPDATE_INFLUENCER_ASSIGNMENT_ERROR,
    payload: { message },
});
export const deleteCampaign = (id) => ({
    type: DELETE_CAMPAIGN,
    payload:{id}
});
export const deleteCampaignSuccess = (status) => ({
    type: DELETE_CAMPAIGN_SUCCESS,
    payload: { status },
});
export const deleteCampaignError = (message) => ({
    type: DELETE_CAMPAIGN_ERROR,
    payload: { message },
});

export const deleteUser = (id,userType) => ({
    type: DELETE_USER,
    payload:{id,userType}
});
export const deleteUserSuccess = (status) => ({
    type: DELETE_USER_SUCCESS,
    payload: { status },
});
export const deleteUserError = (message) => ({
    type: DELETE_USER_ERROR,
    payload: { message },
});

export const getCounts = () => ({
    type: GET_COUNTS
});

export const getCountsSuccess = (counts) => ({
    type: GET_COUNTS_SUCCESS,
    payload: {counts}
});

export const getCountsError = (message) => ({
    type: GET_COUNTS_ERROR,
    payload: {message}
});

export const getGroupedCounts = () => ({
    type: GET_GROUPED_COUNTS
});

export const getGroupedCountsSuccess = (grouped_counts) => ({
    type: GET_GROUPED_COUNTS_SUCCESS,
    payload: {grouped_counts}
});

export const getGroupedCountsError = (message) => ({
    type: GET_GROUPED_COUNTS_ERROR,
    payload: {message}
});

export const getAccountAnalyzer = (id) => ({
    type: GET_ACCOUNT_ANALYZER,
    payload: {id}
});

export const getAccountAnalyzerSuccess = (data) => ({
    type: GET_ACCOUNT_ANALYZER_SUCCESS,
    payload: {data}
});

export const getAccountAnalyzerError = (message) => ({
    type: GET_ACCOUNT_ANALYZER_ERROR,
    payload: {message}
});

export const getPostAnalyzer = (link) => ({
    type: GET_POST_ANALYZER,
    payload: {link}
});

export const getPostAnalyzerSuccess = (data) => ({
    type: GET_POST_ANALYZER_SUCCESS,
    payload: {data}
});

export const getPostAnalyzerError = (message) => ({
    type: GET_POST_ANALYZER_ERROR,
    payload: {message}
});

export const getBetaUsers = (email_verified) => ({
    type: GET_BETA_USERS,
    payload: {email_verified}
});

export const getBetaUsersSuccess = (data) => ({
    type: GET_BETA_USERS_SUCCESS,
    payload: {data}
});

export const getBetaUsersError = (message) => ({
    type: GET_BETA_USERS_ERROR,
    payload: {message}
});


export const setBetaUser = (betaUserId,v_status) => ({
    type: SET_BETA_USER,
    payload: {betaUserId,v_status}
});

export const setBetaUserSuccess = (data) => ({
    type: SET_BETA_USER_SUCCESS,
    payload: {data}
});

export const setBetaUserError = (message) => ({
    type: SET_BETA_USER_ERROR,
    payload: {message}
});

export const getIgUserAnalysis = (username) => ({
    type: GET_IG_USER_ANALYSIS,
    payload: {username}
});

export const getIgUserAnalysisSuccess = (data) => ({
    type: GET_IG_USER_ANALYSIS_SUCCESS,
    payload: {data}
});

export const getIgUserAnalysisError = (message) => ({
    type: GET_IG_USER_ANALYSIS_ERROR,
    payload: {message}
});

export const getIgUserAnalysisClient = (username) => ({
    type: GET_IG_USER_ANALYSIS_CLIENT,
    payload: {username}
});

export const getIgUserAnalysisClientSuccess = (data) => ({
    type: GET_IG_USER_ANALYSIS_CLIENT_SUCCESS,
    payload: {data}
})

export const getIgUserAnalysisClientError = (message) => ({
    type: GET_IG_USER_ANALYSIS_CLIENT_ERROR,
    payload: {message}
});