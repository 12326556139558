import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  CREATE_CAMPAIGN,
  GET_CAMPAIGNS_OF_BRAND,
  GET_INFLUENCERS,
  CREATE_INVITATION,
  GET_CAMPAIGN,
  UPDATE_CAMPAIGN,
  GET_DRAFT_CAMPAIGN_DATA,
  getDraftCampaignDataError,
  getDraftCampaignDataSuccess,
  checkDraftError,
  checkDraftSuccess,
  CHECK_DRAFT,
  compareInfsSuccess,
  COMPARE_INFS,
  respondToRequestedRateError,
  respondToRequestedRateSuccess, RESPOND_TO_REQUESTED_RATE, RESPOND_TO_REQUESTED_RATE_BRAND,
} from "../actions";

import {
  createCampaignSuccess,
  createCampaignError,
  getCampaignsOfBrandSuccess,
  getCampaignsOfBrandError,
  getInfluencersSuccess,
  getInfluencersError,
  createInvitationSuccess,
  createInvitationError,
  getCampaignSuccess,
  getCampaignError, updateCampaignError, updateCampaignSuccess, compareInfsError,
} from "./actions";

import { capitalizeStr, fetchRes, removeEmptyProperties } from "../helpers";
import * as reqBody from "../requestBody";
import moment from "moment";
export function* watchCreateCampaign() {
  console.log("caught create campaign satga");
  yield takeEvery(CREATE_CAMPAIGN, createCampaignFunc);
}

export function* watchGetCampaignsOfBrand() {
  console.log("caught get campaigns saga");
  yield takeEvery(GET_CAMPAIGNS_OF_BRAND, getCampaignsOfBrandFunc);
}

export function* watchGetInfluencers() {
  console.log("caught get influencers saga");
  yield takeEvery(GET_INFLUENCERS, getInfluencersFunc);
}

export function* watchCreateInvitation() {
  console.log("caught create invitation saga");
  yield takeEvery(CREATE_INVITATION, createInvitationFunc);
}

export function* watchGetCampaign() {
  console.log("caught get campaign saga");
  yield takeEvery(GET_CAMPAIGN, getCampaignFunc);
}

export function* watchUpdateCampaign() {
  console.log("caught create campaign satga");
  yield takeEvery(UPDATE_CAMPAIGN, updateCampaignFunc);
}

export function* watchCheckDraft() {
  console.log("caught create campaign satga");
  yield takeEvery(CHECK_DRAFT, checkDraftFunc);
}

export function* watchCompareInfs() {
  console.log("caught create campaign satga");
  yield takeEvery(COMPARE_INFS, compareInfsFunc);
}

export function* watchRespondReq() {
  yield takeEvery(RESPOND_TO_REQUESTED_RATE_BRAND, respondReqFunc);
}

function* updateCampaignFunc({ payload }) {
  console.log("create campaign saga");
  const { createCampaignInput } = payload;
  try {
    const resData = yield call(updateCampaignAsync, createCampaignInput);
    if (!resData.errors) {
      let campaign = resData.data.updateCampaign;
      if(campaign.error) {
        let message = campaign.error.message;
        console.log(message);
        yield put(updateCampaignError(message));
      } else {
        yield put(updateCampaignSuccess(campaign));
      }
    } else {
      throw new Error(resData.errors[0].message);
    }
  } catch (error) {
    yield put(updateCampaignError(error.message));
  }
}


function* createCampaignFunc({ payload }) {
  console.log("create campaign saga");
  const { createCampaignInput } = payload;
  try {
    const resData = yield call(createCampaignAsync, createCampaignInput);
    if (!resData.errors) {
      let campaign = resData.data.createCampaign;
      if(campaign.error) {
        let message = campaign.error.message;
        console.log(message);
          yield put(createCampaignError(message));
      } else {
        yield put(createCampaignSuccess(campaign));
      }
    } else {
      throw new Error(resData.errors[0].message);
    }
  } catch (error) {
    yield put(createCampaignError(error.message));
  }
}

async function updateCampaignAsync(createCampaignInput) {
  const requestBody = reqBody.getUpdateCampaignBody(createCampaignInput,createCampaignInput.status);
  const resData = await fetchRes(requestBody);
  return resData;
}

async function createCampaignAsync(createCampaignInput) {
  const requestBody = reqBody.getCreateCampaignBody(createCampaignInput,createCampaignInput.status);
  const resData = await fetchRes(requestBody);
  return resData;
}

function* getCampaignsOfBrandFunc({ payload }) {
  console.log("get campaign func");
  const { type } = payload;
  try {
    const resData = yield call(getCampaignsOfBrandAsync, type);
      if(resData.data) {
        if(!resData.data.getOneBrand.error) {
          let campaigns = resData.data.getOneBrand[type+"_campaigns"];
          yield put(getCampaignsOfBrandSuccess(type, campaigns));
        } else {
          yield put(getCampaignsOfBrandError(resData.data.getOneBrand.error.message));
        }
      } else {
      throw new Error("Could not get campaigns!");
    }
  } catch (error) {
    yield put(getCampaignsOfBrandError(error.message));
  }
}

async function getCampaignsOfBrandAsync(type) {
  console.log("type of getcampaignsof brand", type);
  const requestBody = reqBody.getGetCampaignsOfBrandBody(type+"_campaigns",localStorage.getItem("userId"));
  const resData = await fetchRes(requestBody);
  return resData;
}

function* getInfluencersFunc({ payload }) {
  console.log("get influencers func");
  let { getInfluencersInput } = payload;

  try {
    const resData = yield call(getInfluencersAsync, getInfluencersInput);
    if (!resData.errors) {
      const influencers = resData.data.getInfluencers;
      if(!influencers.error)
      yield put(getInfluencersSuccess(influencers.list));
      else
        yield put(getInfluencersError(influencers.error.message));

    } else {
      throw new Error("Could not get influencers!");
    }
  } catch (error) {
    yield put(getInfluencersError(error.message));
  }
}

async function getInfluencersAsync(getInfluencersInput) {
  const requestBody = reqBody.getGetInfluencersBody(getInfluencersInput);
  const resData = await fetchRes(requestBody);
  return resData;
}

function* createInvitationFunc({ payload }) {
  console.log("create invitation saga");
  const { influencerId, campaignId } = payload;
  try {
    const resData = yield call(createInvitationAsync, influencerId, campaignId);
    if (!resData.errors) {
      const invitation = resData.data.createInvitation;
      console.log("invitation", invitation);
      yield put(createInvitationSuccess(invitation.id));
    } else {
      throw new Error("Could not add influencer to campaign!");
    }
  } catch (error) {
    yield put(createInvitationError(error.message));
  }
}

async function createInvitationAsync(influencerId, campaignId) {
  const requestBody = reqBody.getCreateInvitationBody(influencerId, campaignId);
  const resData = await fetchRes(requestBody);
  return resData;
}

function* getCampaignFunc({ payload }) {
  console.log("get campaign func");
  const { id } = payload;
  try {
    const resData = yield call(getCampaignAsync, id);
    if (!resData.errors) {
      const campaign = resData.data.getCampaign;
      yield put(getCampaignSuccess(campaign));
    } else {
      throw new Error("Could not get campaign!");
    }
  } catch (error) {
    yield put(getCampaignError(error.message));
  }
}

async function getCampaignAsync(id) {
  const requestBody = reqBody.getGetCampaignBody(id);
  const resData = await fetchRes(requestBody);
  return resData;
}

export function* watchGetDraftCampaignData() {
  yield takeEvery(GET_DRAFT_CAMPAIGN_DATA, getDraftCampaignFunc);
}

function* getDraftCampaignFunc({ payload }) {
  console.log("create campaign saga");
  const { campaign } = payload;
  try {

    const allowed = ['id', 'title', 'amount_payable', 'last_date_to_apply', 'description', 'private', 'influencers_to_be_invited'];
    const filtered = Object.keys(campaign)
        .filter(key => allowed.includes(key))
        .reduce((obj, key) => {
          let val = campaign[key];
          if(key === "last_date_to_apply")
            if(val)
              val = moment(val,'LL');
          if(key === "id")
            obj["campaign_id"] = val;
          else
            obj[key] = val;
          return obj;
        }, {});

    if(filtered['influencers_to_be_invited']) {
      console.log(JSON.parse(filtered['influencers_to_be_invited']));
      let inv = JSON.parse(filtered['influencers_to_be_invited']);
      inv = inv.map(i=>i.id);
        let resData = yield call(getDraftCampaignFuncAsync, inv);
      if (!resData.errors) {
        let influencers = resData.data.getInfluencerNamesByIds;
        if (influencers.error) {
          getDraftCampaignDataError(influencers.error.message);
        } else {
          let list = influencers.list;
          influencers = list.map(res => ({label: res["fullname"], value: res["id"], offered_rate: res["offered_rate"]}));
          filtered['influencers_to_be_invited'] = influencers;
        }
      } else {
        throw new Error(resData.errors[0].message);
      }
    }
    yield put(getDraftCampaignDataSuccess(filtered));
  } catch (error) {
    yield put(getDraftCampaignDataError(error.message));
  }
}

async function getDraftCampaignFuncAsync(ids) {
  const requestBody = reqBody.getInfluencerNamesByIdsBody(ids);
  const resData = await fetchRes(requestBody);
  return resData;
}


function* checkDraftFunc({ payload }) {
  let { influencer_ids } = payload;

  try {
    const resData = yield call(checkDraftAsync, influencer_ids);
    if (!resData.errors) {
      const influencers = resData.data.checkDraft;
      if(!influencers.error)
        yield put(checkDraftSuccess(JSON.parse(influencers.data).packageAmount));
      else
        yield put(checkDraftError(influencers.error.message));

    } else {
      throw new Error("Could not get influencers!");
    }
  } catch (error) {
    yield put(checkDraftError(error.message));
  }
}

async function checkDraftAsync(influencer_ids) {
  const requestBody = reqBody.getCheckDraftBody(influencer_ids);
  const resData = await fetchRes(requestBody);
  return resData;
}

function* compareInfsFunc({ payload }) {
  let { influencer_ids } = payload;

  try {
    const resData = yield call(compareInfsAsync, influencer_ids);
    if (!resData.errors) {
      const influencers = resData.data.compareInfluencers;
      if(!influencers.error)
        yield put(compareInfsSuccess(influencers));
      else
        yield put(compareInfsError(influencers.error.message));

    } else {
      throw new Error("Could not get influencers!");
    }
  } catch (error) {
    yield put(compareInfsError(error.message));
  }
}

async function compareInfsAsync(influencer_ids) {
  const requestBody = reqBody.getCompareInfluencersBody(influencer_ids);
  const resData = await fetchRes(requestBody);
  return resData;
}


function* respondReqFunc({ payload }) {
  const { history, invitation_id, response, requested_rate } = payload;
  try {
    const resData = yield call(respondReqAsync, invitation_id, response, requested_rate);
    if (!resData.errors) {
      const links = resData.data.respondToRequestedRate;
      if(links.error) {
        yield put(respondToRequestedRateError(links.error.message));
      } else
        yield put(respondToRequestedRateSuccess());
    } else {
      throw new Error("Could not add link!");
    }
  } catch (error) {
    yield put(respondToRequestedRateError(error.message));
  }
}

async function respondReqAsync(invitation_id, response, requested_rate) {
  const requestBody = reqBody.getRespondToRequestedRate(invitation_id, response, requested_rate);
  const resData = await fetchRes(requestBody);
  return resData;
}

export default function* rootSaga() {
  yield all([
    fork(watchCreateCampaign),
    fork(watchGetCampaignsOfBrand),
    fork(watchGetInfluencers),
    fork(watchCreateInvitation),
    fork(watchGetCampaign),
    fork(watchUpdateCampaign),
    fork(watchGetDraftCampaignData),
    fork(watchCheckDraft),
    fork(watchCompareInfs),
      fork(watchRespondReq)
  ]);
}
