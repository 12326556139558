import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  LOGIN_ADMIN,
  VERIFY_OTP,
  VERIFY_OTP_ERROR,
  VERIFY_OTP_SUCCESS,
  RESEND_OTP,
  RESEND_OTP_ERROR,
  RESEND_OTP_SUCCESS, FINAL_REGISTER, FINAL_REGISTER_SUCCESS, FINAL_REGISTER_ERROR,
} from "../actions";

import { getItemFromSession } from "../helpers";

const INIT_STATE = {
  otpSent:false,
  loggedIn: getItemFromSession("token") ? true : false,
  userType: getItemFromSession("userType"),
  userId: getItemFromSession("userId"),
  forgotUserMail: "",
  newPassword: "",
  resetPasswordCode: "",
  loading: false,
  changed:"",
  error: "",
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true, error: "" };
    case LOGIN_ADMIN:
      return { ...state, loading: true, error: "" };

    case VERIFY_OTP:
      return { ...state, changed:"", loading: true, error: "" };

    case VERIFY_OTP_SUCCESS:
      return { ...state,changed:"otpVerified", loggedIn: false, loading: false, error: "" };

    case VERIFY_OTP_ERROR:
      return { ...state,changed:"", loading: false, error: action.payload.message };

    case RESEND_OTP:
      return { ...state,changed:"", loading: true, error: "" };

    case RESEND_OTP_SUCCESS:
      return { ...state,changed:"otpResent", loading: false, error: "" };

    case RESEND_OTP_ERROR:
      return { ...state,changed:"", loading: false, error: action.payload.message };

    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        userType: action.payload.userType,
        userId: action.payload.userId,
        loading: false,
        error: "",
      };
    case LOGIN_USER_ERROR:
      return {
        ...state,
        loggedIn: false,
        userType: "",
        loading: false,
        error: action.payload.message,
      };
    case REGISTER_USER:
      return { ...state, loading: true, changed:"", error: "" };
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        changed:"otpSent",
        otpSent:true,
        loggedIn: false,
        userType: action.payload.userType,
        userId: action.payload.userId,
        loading: false,
        error: "",
      };
    case REGISTER_USER_ERROR:
      return {
        ...state,
        changed:"",
        loggedIn: false,
        userType: "",
        loading: false,
        error: action.payload.message,
      };

    case FINAL_REGISTER:
      return { ...state,changed:"", loading: true, error: "" };
    case FINAL_REGISTER_SUCCESS:
      return {
        ...state,
        loggedIn: false,
        changed:"successfullyRegistered",
        userType: action.payload.userType,
        userId: action.payload.userId,
        loading: false,
        error: "",
      };
    case FINAL_REGISTER_ERROR:
      return {
        ...state,
        changed:"",
        loggedIn: false,
        userType: "",
        loading: false,
        error: action.payload.message,
      };

    case LOGOUT_USER:
      return { ...state, user: null, error: "" };
    case FORGOT_PASSWORD:
      return { ...state, loading: true, error: "" };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        forgotUserMail: action.payload,
        error: "",
      };
    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        forgotUserMail: "",
        error: action.payload.message,
      };
    case RESET_PASSWORD:
      return { ...state, loading: true, error: "" };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        newPassword: action.payload,
        resetPasswordCode: "",
        error: "",
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        newPassword: "",
        resetPasswordCode: "",
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};
