import {
  CREATE_CAMPAIGN,
  CREATE_CAMPAIGN_SUCCESS,
  CREATE_CAMPAIGN_ERROR,
  GET_CAMPAIGNS_OF_BRAND,
  GET_CAMPAIGNS_OF_BRAND_SUCCESS,
  GET_CAMPAIGNS_OF_BRAND_ERROR,
  GET_INFLUENCERS,
  GET_INFLUENCERS_SUCCESS,
  GET_INFLUENCERS_ERROR,
  CREATE_INVITATION,
  CREATE_INVITATION_SUCCESS,
  CREATE_INVITATION_ERROR,
  GET_CAMPAIGN,
  GET_CAMPAIGN_SUCCESS,
  GET_CAMPAIGN_ERROR,
  UPDATE_CAMPAIGN,
  UPDATE_CAMPAIGN_SUCCESS,
  UPDATE_CAMPAIGN_ERROR,
  GET_DRAFT_CAMPAIGN_DATA,
  GET_DRAFT_CAMPAIGN_DATA_SUCCESS,
  GET_DRAFT_CAMPAIGN_DATA_ERROR,
  CHECK_DRAFT,
  CHECK_DRAFT_SUCCESS,
  CHECK_DRAFT_ERROR,
  COMPARE_INFS,
  COMPARE_INFS_SUCCESS,
  COMPARE_INFS_ERROR,
  RESPOND_TO_REQUESTED_RATE,
  RESPOND_TO_REQUESTED_RATE_SUCCESS,
  RESPOND_TO_REQUESTED_RATE_ERROR,
  RESPOND_TO_REQUESTED_RATE_BRAND,
  RESPOND_TO_REQUESTED_RATE_SUCCESS_BRAND,
  RESPOND_TO_REQUESTED_RATE_ERROR_BRAND,
} from "../actions";

export const createCampaign = (createCampaignInput) => ({
  type: CREATE_CAMPAIGN,
  payload: { createCampaignInput },
});
export const createCampaignSuccess = (campaign) => ({
  type: CREATE_CAMPAIGN_SUCCESS,
  payload: { campaign },
});
export const createCampaignError = (message) => ({
  type: CREATE_CAMPAIGN_ERROR,
  payload: { message },
});

export const getCampaignsOfBrand = (type) => ({
  type: GET_CAMPAIGNS_OF_BRAND,
  payload: { type },
});


export const getCampaignsOfBrandSuccess = (type, campaigns) => ({
  type: GET_CAMPAIGNS_OF_BRAND_SUCCESS,
  payload: { type, campaigns },
});
export const getCampaignsOfBrandError = (message) => ({
  type: GET_CAMPAIGNS_OF_BRAND_ERROR,
  payload: { message },
});

export const getInfluencers = (getInfluencersInput) => ({
  type: GET_INFLUENCERS,
  payload: { getInfluencersInput },
});
export const getInfluencersSuccess = (influencers) => ({
  type: GET_INFLUENCERS_SUCCESS,
  payload: { influencers },
});
export const getInfluencersError = (message) => ({
  type: GET_INFLUENCERS_ERROR,
  payload: { message },
});

export const createInvitation = (influencerId, campaignId) => ({
  type: CREATE_INVITATION,
  payload: { influencerId, campaignId },
});
export const createInvitationSuccess = (invitationId) => ({
  type: CREATE_INVITATION_SUCCESS,
  payload: { invitationId },
});
export const createInvitationError = (message) => ({
  type: CREATE_INVITATION_ERROR,
  payload: { message },
});

export const getCampaign = (id) => ({
  type: GET_CAMPAIGN,
  payload: { id },
});
export const getCampaignSuccess = (campaign) => ({
  type: GET_CAMPAIGN_SUCCESS,
  payload: { campaign },
});
export const getCampaignError = (message) => ({
  type: GET_CAMPAIGN_ERROR,
  payload: { message },
});

export const updateCampaign = (createCampaignInput) => ({
  type: UPDATE_CAMPAIGN,
  payload: { createCampaignInput },
});
export const updateCampaignSuccess = (campaign) => ({
  type: UPDATE_CAMPAIGN_SUCCESS,
  payload: { campaign },
});
export const updateCampaignError = (message) => ({
  type: UPDATE_CAMPAIGN_ERROR,
  payload: { message },
});

export const getDraftCampaignData = (campaign) => ({
  type: GET_DRAFT_CAMPAIGN_DATA,
  payload: { campaign },
});
export const getDraftCampaignDataSuccess = (data) => ({
  type: GET_DRAFT_CAMPAIGN_DATA_SUCCESS,
  payload: { data },
});
export const getDraftCampaignDataError = (message) => ({
  type: GET_DRAFT_CAMPAIGN_DATA_ERROR,
  payload: { message },
});

export const checkDraft = (influencer_ids) => ({
  type: CHECK_DRAFT,
  payload: { influencer_ids },
});
export const checkDraftSuccess = (data) => ({
  type: CHECK_DRAFT_SUCCESS,
  payload: { data },
});
export const checkDraftError = (message) => ({
  type: CHECK_DRAFT_ERROR,
  payload: { message },
});

export const  compareInfs = (influencer_ids) => ({
  type: COMPARE_INFS,
  payload: { influencer_ids },
});
export const compareInfsSuccess = (data) => ({
  type: COMPARE_INFS_SUCCESS,
  payload: { data },
});
export const compareInfsError = (message) => ({
  type: COMPARE_INFS_ERROR,
  payload: { message },
});


export const respondToRequestedRate = (history,invitation_id, response, requested_rate) => ({
  type: RESPOND_TO_REQUESTED_RATE_BRAND,
  payload: {history,invitation_id, response, requested_rate },
});
export const respondToRequestedRateSuccess = () => ({
  type: RESPOND_TO_REQUESTED_RATE_SUCCESS_BRAND,
});
export const respondToRequestedRateError = (message) => ({
  type: RESPOND_TO_REQUESTED_RATE_ERROR_BRAND,
  payload: { message },
});