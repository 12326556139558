const isStr = (value) => {
  return typeof(value) === 'string'
}

const isEmptyStr = (value) => {
  return value.trim() === ''
}

export {
  isStr,
  isEmptyStr
}