import {
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  TOGGLE_EDIT_MODE,
} from "../actions";

const INIT_STATE = {
  user: {},
  loading: false,
  editMode: false,
  error: "",
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USER:
      return { ...state, loading: true, error: "" };
    case GET_USER_SUCCESS:
      return { ...state, user: action.payload.user, loading: false, error: "" };
    case GET_USER_ERROR:
      return {
        ...state,
        user: {},
        loading: false,
        error: action.payload.message,
      };
    case UPDATE_USER:
      return { ...state, loading: true, error: "" };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        loading: false,
        editMode: false,
        error: "",
      };
    case UPDATE_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case TOGGLE_EDIT_MODE:
      const editMode = state.editMode;
      return {
        ...state,
        editMode: !editMode,
      };
    default:
      return { ...state };
  }
};
