import {
  CREATE_CAMPAIGN,
  CREATE_CAMPAIGN_SUCCESS,
  CREATE_CAMPAIGN_ERROR,
  GET_CAMPAIGNS_OF_BRAND,
  GET_CAMPAIGNS_OF_BRAND_SUCCESS,
  GET_CAMPAIGNS_OF_BRAND_ERROR,
  GET_INFLUENCERS,
  GET_INFLUENCERS_SUCCESS,
  GET_INFLUENCERS_ERROR,
  CREATE_INVITATION,
  CREATE_INVITATION_SUCCESS,
  CREATE_INVITATION_ERROR,
  GET_CAMPAIGN,
  GET_CAMPAIGN_SUCCESS,
  GET_CAMPAIGN_ERROR,
  UPDATE_CAMPAIGN,
  UPDATE_CAMPAIGN_SUCCESS,
  UPDATE_CAMPAIGN_ERROR,
  GET_DRAFT_CAMPAIGN_DATA,
  GET_DRAFT_CAMPAIGN_DATA_SUCCESS,
  GET_DRAFT_CAMPAIGN_DATA_ERROR,
  CHECK_DRAFT,
  CHECK_DRAFT_SUCCESS,
  CHECK_DRAFT_ERROR,
  COMPARE_INFS,
  COMPARE_INFS_SUCCESS,
  COMPARE_INFS_ERROR,
  RESPOND_TO_REQUESTED_RATE,
  RESPOND_TO_REQUESTED_RATE_SUCCESS,
  RESPOND_TO_REQUESTED_RATE_ERROR,
  RESPOND_TO_REQUESTED_RATE_BRAND,
  RESPOND_TO_REQUESTED_RATE_SUCCESS_BRAND,
  RESPOND_TO_REQUESTED_RATE_ERROR_BRAND,
} from "../actions";
import moment from "moment";

function sortById(arr) {
  return arr.sort((a,b)=>{
    return Number(b.id)-Number(a.id);
  });
}

function sortByPostDate(arr) {
  return arr.sort((a,b)=>{
    if(!a.post_date)
      return 1;
    if(!b.post_date)
      return -1;

    let diff = moment(b.post_date,'LL')- moment(a.post_date,'LL');
    if(diff===0)
      return b.id-a.id;
    return moment(b.post_date,'LL')- moment(a.post_date,'LL');
  })
}

const INIT_STATE = {
  campaign: {},
  type:"",
  campaignLoading: false,
  campaignDetails: {},
  draftCampaigns:{},
  postedCampaigns: [],
  activeCampaigns: [],
  completedCampaigns: [],
  influencers: [],
  draftCampaignInput:{},
  lastInvitationId: null,
  create_loading:false,
  loading: false,
  changed_invitation:false,
  draft:0,
  changed:"",
  comparison:[],
  error: "",
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CREATE_CAMPAIGN:
      return { ...state, create_loading: true, error: "" };
    case CREATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaign: action.payload.campaign,
        create_loading: false,
        error: "",
      };
    case CREATE_CAMPAIGN_ERROR:
      return {
        ...state,
        create_loading: false,
        error: action.payload.message
      };
    case GET_CAMPAIGNS_OF_BRAND:
      return { ...state, loading: true, error: "" };
    case GET_CAMPAIGNS_OF_BRAND_SUCCESS:
      let { type, campaigns } = action.payload;
      campaigns = sortByPostDate(campaigns);
      let newState = { ...state, loading: false, error: "",type:type };
      if (type === "posted") {
        newState.postedCampaigns = campaigns;
      }

      if (type === "active") {
        newState.activeCampaigns = campaigns;
      }

      if (type === "completed") {
        newState.completedCampaigns = campaigns;
      }

      if (type === "draft") {
        newState.draftCampaigns = campaigns;
      }
      return newState;
    case GET_CAMPAIGNS_OF_BRAND_ERROR:
      return {
        ...state,
        campaigns: [],
        loading: false,
        error: action.payload.message,
      };
    case GET_CAMPAIGN:
      return { ...state, loading: true, error: "" };
    case GET_CAMPAIGN_SUCCESS:
      const { campaign } = action.payload;
      return { ...state, loading: false, error: "", campaignDetails: campaign };
    case GET_CAMPAIGN_ERROR:
      return {
        ...state,
        campaignDetails: {},
        loading: false,
        error: action.payload.message,
      };
    case GET_INFLUENCERS:
      return { ...state, loading: true, error: "" };
    case GET_INFLUENCERS_SUCCESS:
      return {
        ...state,
        influencers: action.payload.influencers,
        loading: false,
        error: "",
      };
    case GET_INFLUENCERS_ERROR:
      return {
        ...state,
        influencers: [],
        loading: false,
        error: action.payload.message,
      };
    case CREATE_INVITATION:
      return { ...state, loading: true, error: "" };
    case CREATE_INVITATION_SUCCESS:
      return {
        ...state,
        lastInvitationId: action.payload.invitationId,
        loading: false,
        error: "",
      };
    case CREATE_INVITATION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case UPDATE_CAMPAIGN:
      return { ...state, create_loading: true, error: "" };
    case UPDATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaign: action.payload.campaign,
        create_loading: false,
        error: "",
      };
    case UPDATE_CAMPAIGN_ERROR:
      return {
        ...state,
        create_loading: false,
        error: action.payload.message
      };

    case GET_DRAFT_CAMPAIGN_DATA:
      return { ...state, loading: true, error: "" };
    case GET_DRAFT_CAMPAIGN_DATA_SUCCESS:
      return {
        ...state,
        draftCampaignInput: action.payload.data,
        loading: false,
        error: "",
      };
    case GET_DRAFT_CAMPAIGN_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message
      };
    case CHECK_DRAFT:
      return { ...state, loading: true, error: "" };
    case CHECK_DRAFT_SUCCESS:
      return {
        ...state,
        draft: action.payload.data,
        loading: false,
        error: "",
      };
    case CHECK_DRAFT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message
      };
    case COMPARE_INFS:
      return { ...state, loading: true, error: "" };
    case COMPARE_INFS_SUCCESS:
      return {
        ...state,
        comparison: action.payload.data,
        loading: false,
        error: "",
      };
    case COMPARE_INFS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message
      };
    case RESPOND_TO_REQUESTED_RATE_BRAND:
      return { ...state, loading: true, error: "" };
    case RESPOND_TO_REQUESTED_RATE_SUCCESS_BRAND:
      return {
        ...state,
        loading: false,
        changed: "neg_added",
        error: "",
      };
    case RESPOND_TO_REQUESTED_RATE_ERROR_BRAND:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};
