import {
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  TOGGLE_EDIT_MODE,
} from "../actions";

export const getUser = () => ({
  type: GET_USER
});
export const getUserSuccess = (user) => ({
  type: GET_USER_SUCCESS,
  payload: { user },
});
export const getUserError = (message) => ({
  type: GET_USER_ERROR,
  payload: { message },
});
export const updateUser = (updateUserInput) => ({
  type: UPDATE_USER,
  payload: { updateUserInput },
});
export const updateUserSuccess = (user) => ({
  type: UPDATE_USER_SUCCESS,
  payload: { user },
});
export const updateUserError = (message) => ({
  type: UPDATE_USER_ERROR,
  payload: { message },
});
export const toggleEditMode = () => ({
  type: TOGGLE_EDIT_MODE,
});
