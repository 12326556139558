import {
  GET_CAMPAIGNS_FOR_INFLUENCER,
  GET_CAMPAIGNS_FOR_INFLUENCER_SUCCESS,
  GET_CAMPAIGNS_FOR_INFLUENCER_ERROR,
  CREATE_BID,
  CREATE_BID_SUCCESS,
  CREATE_BID_ERROR,
  ADD_LINK,
  ADD_LINK_SUCCESS,
  ADD_LINK_ERROR,
  RESPOND_TO_REQUESTED_RATE,
  RESPOND_TO_REQUESTED_RATE_SUCCESS,
  RESPOND_TO_REQUESTED_RATE_ERROR,
  SAVE_ACCESS_TOKEN, SAVE_ACCESS_TOKEN_SUCCESS, SAVE_ACCESS_TOKEN_ERROR,
} from "../actions";

export const getCampaignsForInfluencer = (type) => ({
  type: GET_CAMPAIGNS_FOR_INFLUENCER,
  payload: { type },
});
export const getCampaignsForInfluencerSuccess = (type, campaigns) => ({
  type: GET_CAMPAIGNS_FOR_INFLUENCER_SUCCESS,
  payload: { type, campaigns },
});
export const getCampaignsForInfluencerError = (message) => ({
  type: GET_CAMPAIGNS_FOR_INFLUENCER_ERROR,
  payload: { message },
});

export const createBid = (campaign_id, influencer_fee) => ({
  type: CREATE_BID,
  payload: { campaign_id, influencer_fee },
});
export const createBidSuccess = (bid) => ({
  type: CREATE_BID_SUCCESS,
  payload: { bid },
});
export const createBidError = (message) => ({
  type: CREATE_BID_ERROR,
  payload: { message },
});

export const addLink = (campaign_id, link) => ({
  type: ADD_LINK,
  payload: {campaign_id, link },
});
export const addLinkSuccess = (campaign_id, link) => ({
  type: ADD_LINK_SUCCESS,
  payload: { campaign_id, link },
});
export const addLinkError = (message) => ({
  type: ADD_LINK_ERROR,
  payload: { message },
});



export const respondToRequestedRate = (invitation_id, response, requested_rate) => ({
  type: RESPOND_TO_REQUESTED_RATE,
  payload: {invitation_id, response, requested_rate },
});
export const respondToRequestedRateSuccess = (message) => ({
  type: RESPOND_TO_REQUESTED_RATE_SUCCESS,
  payload:message
});
export const respondToRequestedRateError = (message) => ({
  type: RESPOND_TO_REQUESTED_RATE_ERROR,
  payload: { message },
});

export const saveAccessToken = (access_token) => ({
  type: SAVE_ACCESS_TOKEN,
  payload: {access_token },
});
export const saveAccessTokenSuccess = () => ({
  type: SAVE_ACCESS_TOKEN_SUCCESS,
});
export const saveAccessTokenError = (message) => ({
  type: SAVE_ACCESS_TOKEN_ERROR,
  payload: { message },
});

